import React from 'react'
import logo from "../../Assets/logo.png"

const index = () => {
    return (
      <>
        <div className="bg-black flex justify-center items-center py-4">
          <div>
            <img src={logo} alt="" className="h-10"/>
          </div>
        </div>
        <div className="grid grid-cols-3">
          <div className="h-1" style={{ background: "#002f75" }}></div>
          <div className="h-1" style={{ background: "#8b1800" }}></div>
          <div className="h-1" style={{ background: "#095b00" }}></div>
        </div>
      </>
    );
}

export default index
