import React, { useState } from "react";
import FilterIcon from "./FilterIcon";

const Filter = ({ filterMenu, setFilterMenu }) => {
  const [showFilterMenu, setShowFilterMenu] = useState(false);

  const handleMenuSelect = (index) => {
    //step1: update the main menu in the array

    let updatedFilterMenu = filterMenu.map((element, elementIndex) =>
      elementIndex === index
        ? { ...element, selected: !element.selected }
        : element
    );

    //step2: Find the menu option that were updated in step 1 to updated its children if any

    let findMenu = updatedFilterMenu.find((el, elIndex) => elIndex === index);

    //step3: declaration of an empty array to keep the updated children array of the menu option that was found in step 2

    let updatedChildren = [];

    //step4: If children array is not empty update every child's selected to true/false based on mother's selected is true or not

    if (findMenu.children.length !== 0) {
      updatedChildren = findMenu.children.map((element, elementIndex) => {
        return { ...element, selected: findMenu.selected ? true : false };
      });
    }

    //step5: now update the mother element with upadated children element

    let finalFilterMenu = updatedFilterMenu.map((element, elementIndex) =>
      elementIndex === index
        ? { ...element, children: updatedChildren }
        : element
    );

    //set the updated menu option in the global state.

    setFilterMenu(finalFilterMenu);
  };

  const handleSubMenuSelect = (menuIndex, subMenuIndex) => {
    //Step1: find the mother menu of these sub Menus

    let findMenu = filterMenu.find((el, elIndex) => elIndex === menuIndex);

    //Step2: Updated the children menu array

    let updatedChildren = findMenu.children.map((element, elementIndex) =>
      elementIndex === subMenuIndex
        ? { ...element, selected: !element.selected }
        : element
    );

    //set a variable to find out how many options are selected from children array

    let selectedCount = 0;

    // update the selectedCount variable as per how many are selected

    updatedChildren.forEach((element) => {
      return element.selected ? selectedCount++ : selectedCount;
    });

    //update the main menu array with updated children and selected will be true if all children menu are selected or else it will be false.

    let finalFilterMenu = filterMenu.map((element, elementIndex) =>
      elementIndex === menuIndex
        ? {
            ...element,
            children: updatedChildren,
            selected: updatedChildren.length === selectedCount ? true : false,
          }
        : element
    );

    //set the updated menu option in the global state.

    setFilterMenu(finalFilterMenu);
  };

  const handleFilterReset = () => {
    let updatedMotherMenu = filterMenu.map((element) =>
      !element.selected ? { ...element, selected: true } : element
    );

    let updatedChildOfSecondMotherMenu = updatedMotherMenu[1].children.map(
      (element) => {
        return !element.selected ? { ...element, selected: true } : element;
      }
    );

    let updatedChildOfThirdMotherMenu = updatedMotherMenu[2].children.map(
      (element) => {
        return !element.selected ? { ...element, selected: true } : element;
      }
    );

   

    let finalUpdate = updatedMotherMenu.map((element, elementIndex) => {
      return {
        ...element,
        children:
          elementIndex === 1
            ? updatedChildOfSecondMotherMenu
            : elementIndex === 2
            ? updatedChildOfThirdMotherMenu
            : [],
      };
    });

    setFilterMenu(finalUpdate)
  };

  return (
    <>
      <div
        className="absolute top-3 h-8 w-full grid place-items-center text-sm cursor-pointer md:hidden"
        style={{ zIndex: 100 }}
        onClick={() => setShowFilterMenu(!showFilterMenu)}
      >
        <div className={`w-full h-full`}>
          {/* <p>Filter</p>
          <BsChevronDown className="text-white sm:text-xl" /> */}
          <FilterIcon
            showFilterMenu={showFilterMenu}
            setShowFilterMenu={setShowFilterMenu}
          />
        </div>
      </div>

      <div
        className={`rounded-md absolute top-10 -left-[2px] transform  md:hidden ${
          showFilterMenu ? "translate-x-0" : "-translate-x-full"
        } w-full bg-gray-600 text-white px-4 transition duration-300`}
        style={{ zIndex: 100 }}
      >
        {filterMenu.map((menu, MenuIndex) => {
          return (
            <div
              className={`${
                showFilterMenu ? "opacity-100" : "opacity-0"
              } transition duration-500`}
              key={MenuIndex}
            >
              <div className="flex space-x-1 mt-3">
                <div className={`w-3 h-3 mt-1 grid place-items-center ${menu.selected ? "bg-gray-100" : "bg-inherit border-2"}`}>
                  
                </div>

                <p
                  className="text-sm cursor-pointer"
                  style={{ marginTop: "-0.5px" }}
                  onClick={() => handleMenuSelect(MenuIndex)}
                >{`${menu.name}`}</p>
              </div>
              {menu.children
                ? menu.children.map((subMenu, subMenuIndex) => {
                    return (
                      <div className="flex space-x-1" key={subMenuIndex}>
                        <div
                          className={`w-3 h-3 rounded-full ${
                            subMenu.selected
                              ? "border-0 bg-gray-100"
                              : "border-2 bg-inherit"
                          } mt-2 ml-2`}
                        ></div>
                        <p
                          className={`pl-1 text-xs py-1 ${
                            subMenuIndex === 2 ? "pb-2" : ""
                          }`}
                          style={{ marginTop: "1.5px" }}
                          onClick={() =>
                            handleSubMenuSelect(MenuIndex, subMenuIndex)
                          }
                        >{`${subMenu.name}`}</p>
                      </div>
                    );
                  })
                : ""}
            </div>
          );
        })}
        <div
          className={`flex justify-center text-sm py-2 ${
            showFilterMenu ? "opacity-100" : "opacity-0"
          } transition duration-1000}`}
        >
          <button
            className="bg-blue-600 bg-opacity-80 rounded-sm  px-2 py-[2px] font-medium"
            onClick={() => {
              handleFilterReset();
            }}
          >
            Reset
          </button>
        </div>
      </div>
    </>
  );
};

export default Filter;
