import React, { useEffect, useState } from "react";
import InputSectionHeader from "../../InputSectionHeader";
import { useDispatch } from "react-redux";
import { addInputData } from "../../../../redux/slice/inputSlice";
import CapitalBusinessInputMonthly from "../../../InputSections/AdvertisingInput/CapitalBusinessInputMonthly";
import CapitalBusinessInputAnnual from "../../../InputSections/AdvertisingInput/CapitalBusinessInputAnnual";
import { setError } from "../../../../redux/slice/errorSlice";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";

const OnlineAdvertisingSection = ({
  className,
  openSection,
  setOpenSection,
  steps,
  setSteps,
  ga,
}) => {
  const dispatch = useDispatch();
  const [temp, setTemp] = useState(0);
  const data = useSelector((state) => state?.data);
  const error = useSelector((state) => state?.error);

  const [k_online_ad_cost_monthly, set_k_online_ad_cost_monthly] = useState(
    ga !== "" && ga ? ga : 10000
  );
  const [l_online_ad_cost_annual, set_l_online_ad_cost_annual] = useState(
    k_online_ad_cost_monthly * 12
  );
  // Checking input field monthly and annually changes
  const [changeFromMonthly, setChangeFromMonthly] = useState(false);
  const [changeFromAnnual, setChangeFromAnnual] = useState(false);

  // Sending input data to redux
  const handleOnlineAdCostDataUpdate = () => {
    if (
      data?.k_online_ad_cost_monthly !== k_online_ad_cost_monthly ||
      data?.l_online_ad_cost_annual !== l_online_ad_cost_annual
    ) {
      dispatch(
        addInputData({
          k_online_ad_cost_monthly,
          l_online_ad_cost_annual,
        })
      );
    }
  };
  // Handling input data changes
  const handleOnlineAdCost = (e, fieldName) => {
    if (!changeFromAnnual && fieldName === "monthly") {
      set_k_online_ad_cost_monthly(e.floatValue);
      set_l_online_ad_cost_annual(e.floatValue * 12);
      setChangeFromMonthly(true);
    } else if (!changeFromMonthly && fieldName === "annual") {
      set_k_online_ad_cost_monthly(e.floatValue / 12);
      set_l_online_ad_cost_annual(e.floatValue);
      setChangeFromAnnual(true);
    }
  };

  useEffect(() => {
    if (temp === undefined) {
      dispatch(setError({ errorThree: true }));
    } else if (temp !== undefined) {
      dispatch(setError({ errorThree: false }));
    }
  }, [temp, dispatch]);

  useEffect(() => {
    setSteps(steps => { return { ...steps, step3: false } });
  }, [k_online_ad_cost_monthly, l_online_ad_cost_annual, steps.step2, setSteps]);

  const handleOpenFields = () => {
    if (steps.step2) {
      setOpenSection({
        section1: false,
        section2: false,
        section3: !openSection.section3,
        section4: false,
        section5: false,
      });
    } else {
      NotificationManager.warning("", "Please complete the previous Step");
    }
  };

  const handleNext = () => {
    if (
      k_online_ad_cost_monthly !== undefined &&
      l_online_ad_cost_annual !== undefined
    ) {
      setSteps({ ...steps, step3: true });
      NotificationManager.success(
        "",
        "This step is completed successfully",
        1000
      );
      setOpenSection({
        section1: false,
        section2: false,
        section3: false,
        section4: true,
        section5: false,
      });
    } else {
      NotificationManager.warning("", "Please fill all the fields", 1000);
    }
  };

  useEffect(() => {
    if (ga !== "") {
      dispatch(
        addInputData({ k_online_ad_cost_monthly, l_online_ad_cost_annual })
      );
    }
  }, [dispatch, ga, k_online_ad_cost_monthly, l_online_ad_cost_annual]);
  return (
    <div className={`${className}`}>
      {/* Section header */}

      <InputSectionHeader
        headerName="Online Advertising Costs"
        errorThree={error?.errorThree}
        step3={steps.step3}
        onClick={() => {
          handleOpenFields();
        }}
      />

      <div
        className={`mx-8 pl-3 border-l-2 border-green-300 pt-2 ${
          !openSection.section3 && "hidden"
        }`}
      >
        <div className="hidden  md:grid grid-cols-8 gap-2 mt-2">
          <div className="col-span-4 text-sm"></div>
          <p className="col-span-2 text-center">Monthly</p>
          <p className="col-span-2 text-center">Annual</p>
        </div>

        <div className="grid grid-cols-8 gap-2 mt-2">
          <p className="col-span-8 md:col-span-4 text-sm  leading-6">
            How much capital did your business deploy on online advertising to
            earn that revenue?
          </p>

          <p className=" col-span-4 text-xs md:text-sm text-left md:hidden">
            Monthly
          </p>
          <p className=" col-span-4 text-xs md:text-sm text-left md:hidden">
            Annual
          </p>

          <CapitalBusinessInputMonthly
            setTemp={setTemp}
            handleOnlineAdCost={handleOnlineAdCost}
            handleOnlineAdCostDataUpdate={handleOnlineAdCostDataUpdate}
            k_online_ad_cost_monthly={k_online_ad_cost_monthly}
            setChangeFromAnnual={setChangeFromAnnual}
          />
          <CapitalBusinessInputAnnual
            setTemp={setTemp}
            handleOnlineAdCost={handleOnlineAdCost}
            handleOnlineAdCostDataUpdate={handleOnlineAdCostDataUpdate}
            l_online_ad_cost_annual={l_online_ad_cost_annual}
            setChangeFromMonthly={setChangeFromMonthly}
          />
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-green-400 px-10 py-2 rounded-md text-white mt-5"
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnlineAdvertisingSection;
