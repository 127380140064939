import React from "react";
import { useSelector } from "react-redux";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const options = {
  layout: {
    padding: {
      bottom: 20,
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label(tooltipItems) {
          return `${tooltipItems?.dataset?.label}: ${tooltipItems.formattedValue}%`;
        },
      },
    },
    title: {
      display: true,
      // text: "Grouped Bar Chart",
    },
    legend: {
      position: "bottom",
      labels: {
        boxWidth: 15,
        boxHeight: 15,
        usePointStyle: true,
        padding: 25,
        textAlign: "right",
        color: "#ffffff",
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index",
    intersect: false,
  },
  animation: {
    delay: (context) => {
      let delay = 0;
      if (context.type === "data" && context.mode === "default") {
        delay = context.dataIndex * 200;
      }
      return delay;
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        color: "#ffffff",
        autoSkip: false,
        maxRotation: 90,
        minRotation: 0,
        font: {
          // size: 9,
          weight: "normal",

          display: function (context) {
            return context.chart.width > 720;
          },
        },
      },
      title: {
        display: true,
        text: "% of Advertising Financed With Revenue Roll",
        color: "#ffffff",
        align: "center",
        padding: 10,
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        color: "#ffffff",
        font: {
          // size: 9,
          weight: "normal",
          display: function (context) {
            return context.chart.width > 720;
          },
        },
        callback: function (value, index, ticks) {
          return value < 0
            ? `(${(value * -1)?.toLocaleString()})`
            : value?.toLocaleString();
        },
      },
      title: {
        display: true,
        text: "Return On Investment",
        fullSize: true,
        font: {
          size: 10,
        },
        color: "#ffffff",
        padding: {
          bottom: 2,
        },
        align: "center",
      },
    },
  },
};

let labels;

export const data = (NRaosImpv, raosImpv, financial_method) => {
  let temp = NRaosImpv;
  let test = raosImpv;

  if (financial_method === "borrowed") {
    temp = [
      NRaosImpv[0],

      NRaosImpv[5],
      NRaosImpv[6],
      NRaosImpv[7],
      NRaosImpv[8],
      NRaosImpv[9],
    ];
    test = [
      raosImpv[0],

      raosImpv[5],
      raosImpv[6],
      raosImpv[7],
      raosImpv[8],
      raosImpv[9],
    ];
    labels = ["Status Quo", "100%", "125%", "150%", "175%", "200%"];
  } else {
    labels = [
      "Status Quo",
      "0",
      "25%",
      "50%",
      "75%",
      "100%",
      "125%",
      "150%",
      "175%",
      "200%",
    ];
  }
  return {
    labels,
    datasets: [
      {
        label: "No ROAS Improvement",
        data: temp,
        backgroundColor: "#3ccf91",
        stack: "Stack 0",
        datalabels: {
          anchor: "end",
          align: "top",
          color: "#ffffff",
          font: {
            size: 9,
          },
          formatter: function (value) {
            return isNaN(value) ? 0 + "%" : value.toLocaleString() + "%";
          },
          display: function (context) {
            return context.chart.width > 720;
          },
        },
      },
      {
        label: "ROAS Improvement",
        data: test,
        backgroundColor: "#5eb7e5",
        stack: "Stack 1",
        datalabels: {
          anchor: "end",
          align: "top",
          color: "#ffffff",
          font: {
            size: 9,
          },
          formatter: function (value) {
            return isNaN(value) ? 0 + "%" : value.toLocaleString() + "%";
          },
          display: function (context) {
            return context.chart.width > 500;
          },
        },
      },
    ],
  };
};

const GroupBarChart = ({ NRaosImpv, raosImpv }) => {
  const fin_data = useSelector((state) => state.data);
  const financial_method = fin_data?.m_financial_method?.value;
  return (
    <div className="h-96 md:h-80 mb-16 relative  ">
      <div className="absolute px-0 xl:px-2 text-black left-4 xl:left-12 top-16 text-xs whitespace-nowrap  py-[2px] bg-green-400 w-[25%] lg:w-[14%] xl:w-[12%] text-center">
        Status Quo
      </div>
      <div className="absolute w-[70%] md:w-[80%] text-xs top-16 right-0 text-black bg-[#5eb7e5] px-2  text-center  py-[2px]">
        Revenue Roll Managed
      </div>
      <div className="absolute md:px-2 text-black right-0 top-[23%] md:top-[27.5%] text-xs  py-[2px] bg-green-400  w-[32%] md:w-[35%] text-center">
        Budget Expansion
      </div>
      {/* <div className="absolute top-[25%] md:top-[19%] left-[2%] text-xs ">
        ROI
      </div> */}
      <div className="flex items-center pt-8 mb-10 ">
        <p className=" text-xs md:text-sm font-semibold text-gray-300 md:py-2  ">
          Maximize Your Returns By Utilizing Our Credit Product
        </p>
        <div className="group relative ">
          <div className=" ml-2 w-5 h-5 text-sm rounded-full bg-gray-500 flex items-center justify-center cursor-pointer">
            ?
          </div>
          <div className="hidden group-hover:block">
            <div
              className="w-5 h-5 rounded-sm bg-toolTip rotate-45 absolute z-50 border-b border-r border-white"
              style={{ right: "7%", bottom: "27px" }}
            ></div>
            <div className="absolute bottom-9 w-72 md:w-96 -right-[30%] xl:-left-10 px-5 py-2 bg-toolTip text-xs rounded-md border border-white text-black">
              <p>
                • Our financing minimizes the down-payment for advertising and
                maximizes your return on campaign investment
              </p>
              <p>
                • For select clients, we are willing to expand the line of
                credit beyond your monthly historical ad spend, exponentially
                increasing your returns on investment
              </p>
            </div>
          </div>
        </div>
      </div>
      <Bar
        options={options}
        data={data(NRaosImpv, raosImpv, financial_method)}
      />
    </div>
  );
};
export default GroupBarChart;
