import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  layout: {
    padding: {
      top: 20,
      bottom: 10,
    },
  },
  plugins: {
    tooltip: {
      mode: "index",
      callbacks: {
        label: function (context) {
          if (context?.raw > 0) {
            return `${context?.dataset?.label}: ${parseInt(
              context?.raw?.toFixed()
            )?.toLocaleString()}`;
          } else if (context.raw < 0) {
            return `${context?.dataset?.label}: (${parseInt(
              (context?.raw * -1)?.toFixed()
            )?.toLocaleString()})`;
          }
        },
      },
    },
    legend: {
      position: "bottom",
      labels: {
        boxWidth: 15,
        boxHeight: 15,
        usePointStyle: true,
        padding: 20,
        textAlign: "right",
        color: "#ffffff",
      },
    },
  },

  responsive: true,
  maintainAspectRatio: false,
  animation: {
    delay: (context) => {
      let delay = 0;
      if (context.type === "data" && context.mode === "default") {
        delay = context.dataIndex * 300 + context.datasetIndex * 100;
      }
      return delay;
    },
  },

  scales: {
    color: "white",
    x: {
      stacked: true,
      grid: {
        display: false,
        tickLength: 20,
      },
      ticks: {
        color: "#ffffff",
        autoSkip: false,
        maxRotation: 0,
        minRotation: 0,
        font: {
          size: 9,
        },
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        color: "#ffffff",
        font: {
          size: 9,
        },
        callback: function (value, index, ticks) {
          return value < 0
            ? `(${(value * -1).toLocaleString()})`
            : value?.toLocaleString();
        },
      },
    },
  },
};

export const data = (labels, revenue, ccost) => {
  return {
    labels,
    datasets: [
      {
        label: "Revenue",
        data: revenue,
        backgroundColor: "#3ccf91",
        maxBarThickness: 50,
        datalabels: {
          anchor: "end",
          offset: -2,
          align: "top",
          color: "#ffffff",
          font: {
            size: 9,
          },
          formatter: function (value, context) {
            if (value > 0) {
              return value.toLocaleString();
            } else if (value < 0) {
              return `(${(value * -1).toLocaleString()})`;
            }
          },
        },
      },
      {
        label: "Contractor Costs",
        data: ccost,
        backgroundColor: "#5eb7e5",
        maxBarThickness: 50,
        datalabels: {
          anchor: "start",
          offset: -2,
          align: "bottom",
          color: "#ffffff",
          font: {
            size: 9,
          },

          formatter: function (value, context) {
            // return context.chart.data.labels[context.dataIndex];
            if (value > 0) {
              return value.toLocaleString();
            } else if (value < 0) {
              return `(${(value * -1).toLocaleString()})`;
            }
          },
        },
      },
    ],
  };
};

const StackBarChart = ({ labels, revenue, ccost, blurb1, blurb2 }) => {
  return (
    <div className="h-80 mt-4 md:mt-0 relative">
      <div className="absolute w-[55%] top-8 lg:top-12 xl:top-8 right-0 text-black bg-[#5EB7E5] px-2  text-center text-xs py-[2px] ">
        Revenue Roll Managed
      </div>
      <div className="absolute md:px-2 lg:px-0 xl:px-2 text-black lg:left-8 left-12 top-8 lg:top-12 xl:top-8 text-xs whitespace-nowrap  py-[2px] xl:py-[2px] bg-green-400  w-[30%] text-center">
        Status Quo
      </div>
      <div className="flex items-center  mb-8">
        <p className="text-xs md:text-sm font-semibold text-gray-300  py-2">
          Revenue Minimizes Cost Per $ of Revenue
        </p>
        <div className="group relative">
          <div className="">
            <div className=" ml-2 w-5 h-5 lg:mt-[-18px] xl:mt-0 text-sm rounded-full bg-gray-500 flex items-center justify-center cursor-pointer">
              <p>?</p>
            </div>

            <div className="hidden group-hover:block">
              <div
                className="w-5 h-5 rounded-sm bg-toolTip rotate-45 absolute z-50 border-b border-r border-white"
                style={{ right: "7%", bottom: "27px" }}
              ></div>
              <div className="absolute bottom-9 w-72 md:w-96 -right-[200%] md:-right-1/2 px-5 py-2 bg-toolTip text-xs rounded-md border border-white text-black">
                <p>
                  • Align your contractors with your success - only pay for what
                  you get
                </p>
                <p>
                  • You pay your contractors to increase traffic and revenue ,
                  so why pay them when they don't perform?
                </p>
                <p>
                  • Revenue Roll only gets paid if we deliver, we wouldn't want
                  it anyway else
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-72 relative">
        <div className="group absolute top-[70%] lg:top-[75%] left-[42%]">
          <div className="w-4 h-4 flex items-center justify-center text-xs text-white cursor-pointer rounded-full bg-gray-500">
            ?
          </div>
          <div className="hidden group-hover:block">
            <div
              className="w-5 h-5 rounded-sm bg-toolTip rotate-45 absolute z-50 border-b border-r border-white"
              style={{ right: "7%", bottom: "27px" }}
            ></div>
            <div className="absolute bottom-9 w-72 md:w-96 right -right-[1000%] md:-right-full  px-5 py-2 bg-toolTip text-xs rounded-md border border-white text-black">
              {/* <p className="font-bold">Same ROAS-</p> */}
              <p>
                If we perform exactly the same as your current provider, your
                costs decrease (and profits increase) by $
                {blurb1?.toLocaleString()} per year.
              </p>
            </div>
          </div>
        </div>
        <div className="group absolute top-[70%] lg:top-[75%] left-[68%]">
          <div className="w-4 text-xs h-4  flex items-center justify-center cursor-pointer rounded-full bg-gray-500">
            ?
          </div>
          <div className="hidden group-hover:block">
            <div
              className="w-5 h-5 rounded-sm bg-toolTip rotate-45 absolute z-50 border-b border-r border-white"
              style={{ right: "7%", bottom: "27px" }}
            ></div>
            <div className="absolute bottom-9 w-72 md:w-96 -right-[40px] px-5 py-2 bg-toolTip text-xs rounded-md border border-white text-black">
              {/* <p className="font-bold">Improved ROAS-</p> */}
              <p>
                Our customers have benefited from an average ROAS increase of
                100% when switching to Revenue Roll. For you, this would
                increase your profits by ${blurb2?.toLocaleString()} per year
              </p>
            </div>
          </div>
        </div>
        <Bar options={options} data={data(labels, revenue, ccost)} />
      </div>
    </div>
  );
};

export default StackBarChart;
