import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Filter from "./Filter";
import SideTitles from "./SideTitles";
import TableColumn from "./TableColumn";
import TableRowTitle from "./TableRowTitle";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";

const width = window.screen.width;

const TableData = ({ tableData }) => {
  const data = useSelector((state) => state.data);
  const financial_method = data?.m_financial_method?.value;
  const columnsContainerWrapper = React.useRef(null);
  const [selectedColumns, setSelectedColumns] = useState(0);
  const [filterMenu, setFilterMenu] = useState([
    {
      name: "Status Quo",
      selected: true,
      children: [],
    },

    {
      name: "Same ROAS",
      selected: true,
      children: [
        { name: "No Financing", selected: true },
        { name: "With Financing", selected: true },
      ],
    },

    {
      name: "Improved ROAS",
      selected: true,
      children: [
        { name: "No Financing", selected: true },
        { name: "100% Financing", selected: true },
        { name: "200% Financing", selected: true },
      ],
    },
  ]);

  useEffect(() => {
    let count = 0;
    filterMenu.forEach((element, index) => {
      if (index === 0) {
        return element.selected ? count++ : null;
      } else {
        element.children.forEach((childElement, childIndex) => {
          return childElement.selected ? count++ : null;
        });
      }
    });

    setSelectedColumns(count);
  }, [filterMenu]);

  useEffect(() => {
    setFilterMenu(fm => {
      if (financial_method === "borrowed") {
        fm[1].selected = false;
        fm[2].selected = false;
        fm[1].children[0].selected = false;
        fm[2].children[0].selected = false;
      } else {
        fm[1].selected = true;
        fm[2].selected = true;
        fm[1].children[0].selected = true;
        fm[2].children[0].selected = true;
      }

      return fm;
    })

    financial_method === "borrowed"
      ? setSelectedColumns(4)
      : setSelectedColumns(6);

  }, [financial_method]);

  const calculateColumnWidth = () => {
    return selectedColumns === 1
      ? "min-w-[90px]"
      : selectedColumns === 2
      ? "min-w-[175px]"
      : selectedColumns === 3
      ? "min-w-[270px]"
      : selectedColumns === 4
      ? "min-w-[360px]"
      : selectedColumns === 5
      ? "min-w-[450px]"
      : selectedColumns === 6
      ? "min-w-[540px]"
      : "";
  };

  const calculateGridColumNumber = () => {
    return selectedColumns === 1
      ? "grid-cols-1"
      : selectedColumns === 2
      ? "grid-cols-2"
      : selectedColumns === 3
      ? "grid-cols-3"
      : selectedColumns === 4
      ? "grid-cols-4"
      : selectedColumns === 5
      ? "grid-cols-5"
      : selectedColumns === 6
      ? "grid-cols-6"
      : "";
  };

  const sideScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
    }, speed);
  };

  return (
    <>
      <div
        className="grid grid-cols-10 overflow-y-hidden relative bg-gray-100 text-black rounded-md scrollbar scrollbar-thin scrollbar-thumb-green-400 scrollbar-track-gray-900 pb-3"
        ref={columnsContainerWrapper}
      >
        <div className="sticky top-0 left-0 col-span-5 md:col-span-4 grid grid-cols-4">
          <Filter filterMenu={filterMenu} setFilterMenu={setFilterMenu} />
          <SideTitles />
          <TableRowTitle className="col-span-3" />

          {selectedColumns > 2 && (
            <div
              className="absolute w-[200%] flex justify-between top-[51%] md:hidden"
              style={{ zIndex: 200 }}
            >
              <div className="w-1/2 flex justify-end">
                <button
                  onClick={() => {
                    sideScroll(columnsContainerWrapper.current, 25, 90, -10);
                  }}
                  className="w-6 h-6 bg-green-300 opacity-70 text-gray-800 rounded-md grid place-items-center "
                >
                  <AiOutlineCaretLeft />
                </button>
              </div>

              <button
                onClick={() => {
                  sideScroll(columnsContainerWrapper.current, 25, 90, 10);
                }}
                className="w-6 h-6 bg-green-300 opacity-70 text-gray-800 rounded-md grid place-items-center "
              >
                <AiOutlineCaretRight />
              </button>
            </div>
          )}
        </div>

        <div
          className={`cols-span-5 md:col-span-6 ${calculateGridColumNumber()} grid  ${calculateColumnWidth()} md:min-w-[0px]`}
        >
          <div
            className={`hidden col-span-6 md:grid ${calculateGridColumNumber()}  h-9 md:h-8`}
          >
            <div className="col-span-1  bg-green-400 text-black  py-[2px] mt-3 flex items-center justify-center lg:text-[8.5px] xl:text-xs text-[9px] md:text-xs mr-1">
              Status Quo
            </div>
            <div
              className={`col-span-${
                selectedColumns - 1
              } bg-[#5EB7E5] text-black py-[2px] mt-3 flex items-center justify-center  lg:text-[8.5px] text-[9px] md:text-xs xl:text-xs `}
            >
              RevenueRoll Managed
            </div>
          </div>

          {filterMenu[0].selected && (
            <TableColumn
              columnData={tableData?.status_quo}
              columnType="withBorder"
              title={width < 680 ? "Status Quo" : ""}
            />
          )}
          {(filterMenu[1].selected ||
            filterMenu[1].children[0].selected ||
            filterMenu[1].children[1].selected) && (
            <>
              {filterMenu[1].children[0].selected && (
                <TableColumn
                  columnData={tableData?.same_roas.unlevered}
                  title="Same ROAS"
                  subTitle="No Financing"
                />
              )}
              {filterMenu[1].children[1].selected && (
                <TableColumn
                  columnData={tableData?.same_roas.levered100}
                  columnType="withBorder"
                  title="Same ROAS"
                  subTitle="With Financing"
                />
              )}
            </>
          )}
          {(filterMenu[2].selected ||
            filterMenu[2].children[0].selected ||
            filterMenu[2].children[1].selected ||
            filterMenu[2].children[2].selected) && (
            <>
              {filterMenu[2].children[0].selected && (
                <TableColumn
                  columnData={tableData?.improved_roas.unlevered}
                  title="Improved ROAS"
                  subTitle="No Financing"
                />
              )}
              {filterMenu[2].children[1].selected && (
                <TableColumn
                  columnData={tableData?.improved_roas.levered100}
                  title="Improved ROAS"
                  subTitle=" 100% Financing"
                />
              )}
              {filterMenu[2].children[2].selected && (
                <TableColumn
                  columnData={tableData?.improved_roas.levered200}
                  columnType="withBorder"
                  title="Improved ROAS"
                  subTitle=" 200% Financing"
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default TableData;
