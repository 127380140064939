import React from "react";
import { BsChevronDown } from "react-icons/bs";
import { MdDone } from "react-icons/md";
const InputSectionHeader = ({
  step1,
  step2,
  step3,
  step4,
  step5,
  headerName,
  onClick,
  errorTwo,
  errorThree,
  errorFour,
  errorFive,
}) => {
  return (
    <div
      className="flex justify-between  mx-6 cursor-pointer my-2"
      onClick={onClick}
    >
      <div className="flex  w-full  space-x-3">
        {/* errorTwo || errorThree || errorFour || errorFive || */}
        {step1 || step2 || step3 || step4 || step5 ? null : (
          <div
            className={`md:h-5 md:w-5 h-4 w-4 border-4 rounded-full border-green-400 `}
            style={{ marginTop: "6px" }}
          ></div>
        )}
        {(step1 || step2 || step3 || step4 || step5) && (
          <div
            className={`h-4 w-4 md:w-5 md:h-5 rounded-full bg-green-400 grid place-items-center mt-2`}
          >
            <MdDone className="text-xs md:text-base" />
          </div>
        )}

        <div className="w-full">
          <p className=" md:text-2xl">{headerName}</p>
        </div>
      </div>

      <BsChevronDown
        className="text-primary sm:text-xl"
        style={{ marginTop: "6px" }}
      />
    </div>
  );
};

export default React.memo(InputSectionHeader);
