import React from "react";
import NumberFormat from "react-number-format";

const EventWorthInputMonthly = ({
  setTemp,
  handleHowMuchEventGrowth,
  handleOnlineRevenueDataUpdate,
  setChangeFromAnnual,
  g_event_worth_monthly,
}) => {
  return (
    <div
      className={`col-span-8 md:col-span-4 h-10 px-2 `}
      style={{
        background: "#212930",
        borderBottom: "2px solid #69D49A",
      }}
    >
      <NumberFormat
        thousandsGroupStyle="thousand"
        prefix={"$ "}
        // suffix={" %"}
        decimalSeparator="."
        displayType="input"
        type="text"
        thousandSeparator={true}
        allowNegative={true}
        className={`bg-inherit w-full h-full  caret-white focus:outline-none `}
        onValueChange={(e) => {
          setTemp(e.floatValue);
          setChangeFromAnnual(false);
          handleHowMuchEventGrowth(e, "monthly");
        }}
        value={g_event_worth_monthly}
        decimalScale={2}
        onBlur={() => {
          // if (temp !== undefined) {
          handleOnlineRevenueDataUpdate("event_worth");
          // }
        }}
      />
    </div>
  );
};

export default EventWorthInputMonthly;
