import React, { useEffect, useState } from "react";
import InputSectionHeader from "../../InputSectionHeader";
import { useSelector, useDispatch } from "react-redux";
import { addInputData } from "../../../../redux/slice/inputSlice";
import RevenueInputMonthly from "../../../InputSections/OnlineRevenueInput/RevenueInputMonthly";
import RevenueInputAnnual from "../../../InputSections/OnlineRevenueInput/RevenueInputAnnual";
import OnlineChannelInputMonthly from "../../../InputSections/OnlineRevenueInput/OnlineChannelInputMonthly";
import OnlineChannelInputAnnual from "../../../InputSections/OnlineRevenueInput/OnlineChannelInputAnnual";
import EventWorthInputMonthly from "../../../InputSections/OnlineRevenueInput/EventWorthInputMonthly";
import RevenueEarnedInputMonthly from "../../../InputSections/OnlineRevenueInput/RevenueEarnedInputMonthly";
import RevenueEarnedInputAnnual from "../../../InputSections/OnlineRevenueInput/RevenueEarnedInputAnnual";
import { setError } from "../../../../redux/slice/errorSlice";
import { NotificationManager } from "react-notifications";

const OnlineRevenueSection = ({
  className,
  openSection,
  setOpenSection,
  steps,
  setSteps,
  c,
  e,
  g,
  i,
  param,
}) => {
  const [temp, setTemp] = useState(0);
  // Input field states
  const [c_online_revenue_monthly, setC_online_revenue_monthly] = useState(
    c !== "" && c ? c : 20000
  );
  const [d_online_revenue_annual, setD_online_revenue_annual] = useState(
    c_online_revenue_monthly * 12
  );

  const [changeFromMonthly, setChangeFromMonthly] = useState(false);
  const [changeFromAnnual, setChangeFromAnnual] = useState(false);

  const [e_online_event_monthly, setE_online_event_monthly] = useState(
    e !== "" && e ? e : 1000
  );
  const [f_online_event_annual, setF_online_event_annual] = useState(
    e_online_event_monthly * 12
  );
  const [g_event_worth_monthly, set_g_event_worth_monthly] = useState(
    g !== "" && g ? g : 20
  );
  const [h_event_worth_annual, set_h_event_worth_annual] = useState(
    g_event_worth_monthly * 12
  );
  const [i_revenue_earned_monthly, set_i_revenue_earned_monthly] = useState(
    i !== "" && i ? i : e_online_event_monthly * g_event_worth_monthly
  );
  const [j_revenue_earned_annual, set_j_revenue_earned_annual] = useState(
    i_revenue_earned_monthly * 12
  );

  const dispatch = useDispatch();
  const data = useSelector((state) => state.data);
  const error = useSelector((state) => state.error);

  const a = data?.a_client_type?.value;

  useEffect(() => {
    if (temp === undefined) {
      dispatch(setError({ errorTwo: true }));
    } else if (temp !== undefined) {
      dispatch(setError({ errorTwo: false }));
    }
  }, [temp, dispatch]);

  const handleOnlineRevenueDataUpdate = (fieldName) => {
    switch (fieldName) {
      case "online_revenue":
        if (
          data?.c_online_revenue_monthly !== c_online_revenue_monthly &&
          data?.d_online_revenue_annual !== d_online_revenue_annual
        ) {
          dispatch(
            addInputData({ c_online_revenue_monthly, d_online_revenue_annual })
          );
        }
        break;
      case "online_event":
        if (data?.e_online_event_monthly !== e_online_event_monthly)
          dispatch(
            addInputData({
              e_online_event_monthly,
              f_online_event_annual,
              i_revenue_earned_monthly,
              j_revenue_earned_annual,
            })
          );
        break;
      case "event_worth":
        if (data?.g_event_worth_monthly !== g_event_worth_monthly)
          dispatch(
            addInputData({
              g_event_worth_monthly,
              h_event_worth_annual,
              i_revenue_earned_monthly,
              j_revenue_earned_annual,
            })
          );
        break;
      case "revenue_earned" || "online_event":
        if (data?.i_revenue_earned_monthly !== i_revenue_earned_monthly)
          dispatch(
            addInputData({ i_revenue_earned_monthly, j_revenue_earned_annual })
          );
        break;
      default:
        return;
    }
  };

  // Input field value handler

  const handleOnlineRevenue = (e, fieldName) => {
    if (!changeFromAnnual && fieldName === "monthly") {
      setC_online_revenue_monthly(e.floatValue);
      setD_online_revenue_annual(e.floatValue * 12);
      setChangeFromMonthly(true);
    } else if (!changeFromMonthly && fieldName === "annual") {
      setD_online_revenue_annual(e.floatValue);
      setC_online_revenue_monthly(e.floatValue / 12);
      setChangeFromAnnual(true);
    }
  };

  const handleHowManyEvents = (e, fieldName) => {
    if (!changeFromAnnual && fieldName === "monthly") {
      setE_online_event_monthly(e.floatValue);
      setF_online_event_annual(e.floatValue * 12);
      set_i_revenue_earned_monthly(e.floatValue * g_event_worth_monthly);
      setChangeFromMonthly(true);
    } else if (!changeFromMonthly && fieldName === "annual") {
      setF_online_event_annual(e.floatValue);
      setE_online_event_monthly(e.floatValue / 12);
      set_i_revenue_earned_monthly((e.floatValue / 12) * g_event_worth_monthly);
      set_j_revenue_earned_annual(e.floatValue * g_event_worth_monthly);
      setChangeFromAnnual(true);
    }
  };

  const handleHowMuchEventGrowth = (e, fieldName) => {
    if (!changeFromAnnual && fieldName === "monthly") {
      set_g_event_worth_monthly(e.floatValue);
      set_h_event_worth_annual(e.floatValue * 12);
      set_i_revenue_earned_monthly(e.floatValue * e_online_event_monthly);
      setChangeFromMonthly(true);
    } else if (!changeFromMonthly && fieldName === "annual") {
      set_g_event_worth_monthly(e.floatValue / 12);
      set_h_event_worth_annual(e.floatValue);
      // set_i_revenue_earned_monthly(e.floatValue / 12) * e_online_event_monthly);
      setChangeFromAnnual(true);
    }
  };
  const howMuchRevenueEarned = (e, fieldName) => {
    if (!changeFromAnnual && fieldName === "monthly") {
      set_i_revenue_earned_monthly(e.floatValue);
      set_j_revenue_earned_annual(e.floatValue * 12);
      setChangeFromMonthly(true);
    } else if (!changeFromMonthly && fieldName === "annual") {
      set_i_revenue_earned_monthly(e.floatValue / 12);
      set_j_revenue_earned_annual(e.floatValue);
      setChangeFromAnnual(true);
    }
  };
  useEffect(() => {
    setSteps(steps => { return { ...steps, step2: false } });
  }, [c_online_revenue_monthly, d_online_revenue_annual, steps.step1, setSteps]);

  const handleOpenFields = () => {
    if (steps.step1) {
      setOpenSection({
        section1: false,
        section2: !openSection.section2,
        section3: false,
        section4: false,
        section5: false,
      });
    } else {
      NotificationManager.warning("", "Please complete the previous Step");
    }
  };

  const handleNext = () => {
    if (
      a === "event" &&
      e_online_event_monthly !== undefined &&
      f_online_event_annual !== undefined &&
      g_event_worth_monthly !== undefined &&
      h_event_worth_annual !== undefined &&
      i_revenue_earned_monthly !== undefined &&
      j_revenue_earned_annual !== undefined
    ) {
      setSteps({ ...steps, step2: true });
      NotificationManager.success(
        "",
        "This step is completed successfully",
        1000
      );
      setOpenSection({
        section1: false,
        section2: false,
        section3: true,
        section4: false,
        section5: false,
      });
    } else if (
      a === "ecommerce" &&
      c_online_revenue_monthly !== undefined &&
      d_online_revenue_annual !== undefined
    ) {
      setSteps({ ...steps, step2: true });
      setOpenSection({
        section1: false,
        section2: false,
        section3: true,
        section4: false,
        section5: false,
      });
      NotificationManager.success(
        "",
        "This step is completed successfully",
        1000
      );
    } else {
      NotificationManager.warning("", "Please fill all the fields", 1000);
    }
  };
  // setting data
  useEffect(() => {
    if (param === "ecommerce" && c !== "") {
      dispatch(
        addInputData({
          c_online_revenue_monthly,
          d_online_revenue_annual,
        })
      );
    } else if (param === "event" && (e !== "" || g !== "" || i !== "")) {
      dispatch(
        addInputData({
          e_online_event_monthly,
          f_online_event_annual,
          g_event_worth_monthly,
          h_event_worth_annual,
          i_revenue_earned_monthly,
          j_revenue_earned_annual,
        })
      );
    }
  }, [c, c_online_revenue_monthly, d_online_revenue_annual, dispatch, e, e_online_event_monthly, f_online_event_annual, g, g_event_worth_monthly, h_event_worth_annual, i, i_revenue_earned_monthly, j_revenue_earned_annual, param]);
  return (
    <div className={`${className}`}>
      {/* Section header */}

      <InputSectionHeader
        headerName="Online Revenue"
        errorTwo={error?.errorTwo}
        step2={steps?.step2}
        onClick={() => {
          handleOpenFields();
        }}
      />

      <div
        className={`mx-8 pl-3 border-l-2 border-green-300 pt-2 ${
          !openSection.section2 && "hidden"
        }`}
      >
        <div className="hidden  md:grid grid-cols-8 gap-2">
          <div className="col-span-4 text-sm"></div>
          <p className="col-span-2 text-center">Monthly</p>
          <p className="col-span-2 text-center">Annual</p>
        </div>
        <div
          className={`grid grid-cols-8 gap-2 mt-2 ${
            data?.a_event_type?.value === "ecommerce" ||
            data?.a_client_type?.value === "event"
              ? "hidden"
              : "block"
          }`}
        >
          <p className="col-span-8 md:col-span-4 text-sm  leading-6">
            How much revenue did your business earn through online channels?
          </p>
          <p className="col-span-4 text-xs md:text-sm text-left md:hidden">
            Monthly
          </p>
          <p className="col-span-4 text-xs md:text-sm text-left md:hidden">
            Annual
          </p>
          <RevenueInputMonthly
            setChangeFromAnnual={setChangeFromAnnual}
            temp={temp}
            setTemp={setTemp}
            handleOnlineRevenue={handleOnlineRevenue}
            c_online_revenue_monthly={c_online_revenue_monthly}
            setC_online_revenue_monthly={setC_online_revenue_monthly}
            handleOnlineRevenueDataUpdate={handleOnlineRevenueDataUpdate}
          />
          <RevenueInputAnnual
            setChangeFromMonthly={setChangeFromMonthly}
            temp={temp}
            setTemp={setTemp}
            handleOnlineRevenue={handleOnlineRevenue}
            d_online_revenue_annual={d_online_revenue_annual}
            handleOnlineRevenueDataUpdate={handleOnlineRevenueDataUpdate}
          />
        </div>
        {data?.a_client_type?.value === "event" && (
          <>
            <div className="grid grid-cols-8 gap-2 mt-2  mb-6 md:mb-0">
              <p className="col-span-8 md:col-span-4 text-sm  leading-6">
                How many events did you get through online channels?
              </p>
              <p className="col-span-4 text-xs md:text-sm text-left md:hidden">
                Monthly
              </p>
              <p className="col-span-4 text-xs md:text-sm text-left md:hidden">
                Annual
              </p>
              <OnlineChannelInputMonthly
                setTemp={setTemp}
                handleOnlineRevenueDataUpdate={handleOnlineRevenueDataUpdate}
                handleHowManyEvents={handleHowManyEvents}
                setChangeFromAnnual={setChangeFromAnnual}
                e_online_event_monthly={e_online_event_monthly}
              />
              <OnlineChannelInputAnnual
                setTemp={setTemp}
                handleOnlineRevenueDataUpdate={handleOnlineRevenueDataUpdate}
                handleHowManyEvents={handleHowManyEvents}
                setChangeFromMonthly={setChangeFromMonthly}
                f_online_event_annual={f_online_event_annual}
              />
            </div>

            <div className={`grid grid-cols-8 gap-2 mt-2  mb-6 md:mb-0`}>
              <p className="col-span-8 md:col-span-4 text-sm  leading-6">
                What is each event worth to you?
              </p>
              <p className="col-span-4 text-xs md:text-sm text-left md:hidden">
                Monthly
              </p>
              {/* <p className="col-span-4 text-left md:hidden">Annual</p> */}

              <EventWorthInputMonthly
                setTemp={setTemp}
                setChangeFromAnnual={setChangeFromAnnual}
                handleHowMuchEventGrowth={handleHowMuchEventGrowth}
                handleOnlineRevenueDataUpdate={handleOnlineRevenueDataUpdate}
                g_event_worth_monthly={g_event_worth_monthly}
              />
              {/* <EventWorthInputAnnual
                setTemp={setTemp}
                setChangeFromMonthly={setChangeFromMonthly}
                handleHowMuchEventGrowth={handleHowMuchEventGrowth}
                handleOnlineRevenueDataUpdate={handleOnlineRevenueDataUpdate}
                h_event_worth_annual={h_event_worth_annual}
              /> */}
            </div>

            <div className="grid grid-cols-8 gap-2 mt-2 ">
              <p className="col-span-8 md:col-span-4 text-sm  leading-6">
                Estimated revenue earned from events.
              </p>
              <p className="col-span-4 text-xs md:text-sm text-left md:hidden">
                Monthly
              </p>
              <p className="col-span-4 text-xs md:text-sm text-left md:hidden">
                Annual
              </p>
              <RevenueEarnedInputMonthly
                setTemp={setTemp}
                handleOnlineRevenueDataUpdate={handleOnlineRevenueDataUpdate}
                howMuchRevenueEarned={howMuchRevenueEarned}
                setChangeFromAnnual={setChangeFromAnnual}
                i_revenue_earned_monthly={i_revenue_earned_monthly}
              />
              <RevenueEarnedInputAnnual
                setTemp={setTemp}
                handleOnlineRevenueDataUpdate={handleOnlineRevenueDataUpdate}
                howMuchRevenueEarned={howMuchRevenueEarned}
                setChangeFromMonthly={setChangeFromMonthly}
                j_revenue_earned_annual={j_revenue_earned_annual}
              />
            </div>
          </>
        )}
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-green-400 px-10 py-2 rounded-md text-white mt-5"
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default OnlineRevenueSection;
