import React, { useEffect, useState } from "react";
import InputSectionHeader from "../../InputSectionHeader";
import { useDispatch } from "react-redux";
import { addInputData } from "../../../../redux/slice/inputSlice";
import RetainAgencyInputMonthly from "../../../InputSections/MarketingInput/RetainAgencyInputMonthly";
import RetainAgencyInputAnnual from "../../../InputSections/MarketingInput/RetainAgencyInputAnnual";
import { setError } from "../../../../redux/slice/errorSlice";
import { useSelector } from "react-redux";
import { NotificationManager } from "react-notifications";

const MarketingAgencySection = ({
  className,
  openSection,
  setOpenSection,
  steps,
  setSteps,
}) => {
  const dispatch = useDispatch();
  const [temp, setTemp] = useState(0);
  const data = useSelector((state) => state.data);
  const error = useSelector((state) => state.error);

  const [p_marketing_agency_cost_monthly, set_p_marketing_agency_cost_monthly] =
    useState(4500);
  const [q_marketing_agency_cost_annual, set_q_marketing_agency_cost_annual] =
    useState(p_marketing_agency_cost_monthly * 12);
  // Checking input field monthly and annually changes
  const [changeFromMonthly, setChangeFromMonthly] = useState(false);
  const [changeFromAnnual, setChangeFromAnnual] = useState(false);

  // Sending input data to redux
  const handleMarketingAgencyDataUpdate = () => {
    if (
      data?.p_marketing_agency_cost_monthly !==
        p_marketing_agency_cost_monthly ||
      data?.q_marketing_agency_cost_annual !== q_marketing_agency_cost_annual
    )
      dispatch(
        addInputData({
          p_marketing_agency_cost_monthly,
          q_marketing_agency_cost_annual,
        })
      );
  };
  const handleMarketingAgency = (e, fieldName) => {
    if (!changeFromAnnual && fieldName === "monthly") {
      set_p_marketing_agency_cost_monthly(e.floatValue);
      set_q_marketing_agency_cost_annual(e.floatValue * 12);
      setChangeFromMonthly(true);
    } else if (!changeFromMonthly && fieldName === "annual") {
      set_p_marketing_agency_cost_monthly(e.floatValue / 12);
      set_q_marketing_agency_cost_annual(e.floatValue);
      setChangeFromAnnual(true);
    }
  };

  useEffect(() => {
    if (temp === undefined) {
      dispatch(setError({ errorFive: true }));
    } else if (temp !== undefined) {
      dispatch(setError({ errorFive: false }));
      // setSteps({ ...steps, step5: true });
    }
  }, [temp, dispatch]);

  const handleOpenFields = () => {
    if (steps.step4) {
      setOpenSection({
        section1: false,
        section2: false,
        section3: false,
        section4: false,
        section5: !openSection.section5,
      });
      setSteps({ ...steps, step5: true });
    } else {
      NotificationManager.warning("", "Please complete the previous Step");
    }
  };

  useEffect(() => {
    if (steps.step4) {
      setSteps(steps => { return {...steps, step5: true} });
    }
  }, [steps.step4, temp, setSteps]);

  return (
    <div className={`${className}`}>
      {/* Section header */}

      <InputSectionHeader
        headerName="Marketing Agency and/or Contractor Costs"
        errorFive={error?.errorFive}
        step5={steps.step5}
        onClick={() => handleOpenFields()}
      />

      <div
        className={`mx-8 pl-3 border-l-2 border-green-300 pt-2 ${
          !openSection.section5 && "hidden"
        }`}
      >
        <div className="hidden md:grid grid-cols-8 gap-2 mt-2">
          <div className="col-span-4 text-sm"></div>
          <p className="col-span-2 text-center">Monthly</p>
          <p className="col-span-2 text-center">Annual</p>
        </div>

        <div className="grid grid-cols-8 gap-2 mt-2">
          <p className="col-span-8 md:col-span-4 text-sm  leading-6">
            If you retain an agency or contractor to run your ads, put their
            costs here. If you run them yourself, put zero.
          </p>

          <p className="md:hidden text-xs md:text-sm col-span-4 text-left ">
            Monthly
          </p>
          <p className="md:hidden text-xs md:text-sm col-span-4 text-left">
            Annual
          </p>

          <RetainAgencyInputMonthly
            setTemp={setTemp}
            setChangeFromAnnual={setChangeFromAnnual}
            handleMarketingAgency={handleMarketingAgency}
            handleMarketingAgencyDataUpdate={handleMarketingAgencyDataUpdate}
            p_marketing_agency_cost_monthly={p_marketing_agency_cost_monthly}
          />
          <RetainAgencyInputAnnual
            setTemp={setTemp}
            setChangeFromMonthly={setChangeFromMonthly}
            handleMarketingAgency={handleMarketingAgency}
            handleMarketingAgencyDataUpdate={handleMarketingAgencyDataUpdate}
            q_marketing_agency_cost_annual={q_marketing_agency_cost_annual}
          />
        </div>
      </div>
    </div>
  );
};

export default MarketingAgencySection;
