import React from "react";
import NumberFormat from "react-number-format";

const CapitalBusinessInputMonthly = ({
  setTemp,
  setChangeFromAnnual,
  handleOnlineAdCost,
  handleOnlineAdCostDataUpdate,
  k_online_ad_cost_monthly,
}) => {
  return (
    <div
      className={`col-span-4 md:col-span-2 h-10 px-2 `}
      style={{ background: "#212930", borderBottom: "2px solid #69D49A" }}
    >
      <NumberFormat
        thousandsGroupStyle="thousand"
        prefix={"$ "}
        // suffix={" %"}
        decimalSeparator="."
        displayType="input"
        type="text"
        thousandSeparator={true}
        allowNegative={true}
        className={`bg-inherit w-full h-full  caret-white focus:outline-none `}
        onValueChange={(e) => {
          setTemp(e.floatValue);
          setChangeFromAnnual(false);
          handleOnlineAdCost(e, "monthly");
        }}
        value={k_online_ad_cost_monthly}
        decimalScale={2}
        onBlur={() => {
          // if (temp !== undefined) {
          handleOnlineAdCostDataUpdate();
          // }
        }}
      />
    </div>
  );
};

export default CapitalBusinessInputMonthly;
