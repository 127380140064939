import React, { useEffect, useState } from "react";

import InputSectionHeader from "../../InputSectionHeader";
import InputDropdown from "../../InputDropdown";
import { useDispatch } from "react-redux";
import { addInputData } from "../../../../redux/slice/inputSlice";
import { useSelector } from "react-redux";
import InterestRateInputMonthly from "../../../InputSections/FinancingInput/InterestRateInputMonthly";
import InterestRateInputAnnual from "../../../InputSections/FinancingInput/InterestRateInputAnnual";
import { setError } from "../../../../redux/slice/errorSlice";
import { NotificationManager } from "react-notifications";

const first_dropdown_options = [
  { value: "cash", label: "Cash" },
  { value: "borrowed", label: "Borrowed" },
];

const FinancingMethodSection = ({
  className,
  openSection,
  setOpenSection,
  steps,
  setSteps,
}) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state?.data);
  const error = useSelector((state) => state.error);
  const [temp, setTemp] = useState(0);

  const [
    n_financial_interest_rate_monthly,
    set_n_financial_interest_rate_monthly,
  ] = useState(2);
  const [
    o_financial_interest_rate_annual,
    set_o_financial_interest_rate_annual,
  ] = useState(n_financial_interest_rate_monthly * 12);
  // Checking input field monthly and annually changes
  const [changeFromMonthly, setChangeFromMonthly] = useState(false);
  const [changeFromAnnual, setChangeFromAnnual] = useState(false);

  // Sending input data to redux
  const handleFinantialInterestDataUpdate = () => {
    if (
      data?.n_financial_interest_rate_monthly !==
        n_financial_interest_rate_monthly ||
      data?.o_financial_interest_rate_annual !==
        o_financial_interest_rate_annual
    ) {
      dispatch(
        addInputData({
          n_financial_interest_rate_monthly,
          o_financial_interest_rate_annual,
        })
      );
    }
  };
  const handleFinancialInterest = (e, fieldName) => {
    if (!changeFromAnnual && fieldName === "monthly") {
      set_n_financial_interest_rate_monthly(e.floatValue);
      set_o_financial_interest_rate_annual(e.floatValue * 12);
      setChangeFromMonthly(true);
    } else if (!changeFromMonthly && fieldName === "annual") {
      set_n_financial_interest_rate_monthly(e.floatValue / 12);
      set_o_financial_interest_rate_annual(e.floatValue);
      setChangeFromAnnual(true);
    }
  };
  useEffect(() => {
    if (temp === undefined) {
      dispatch(setError({ errorFour: true }));
    } else if (temp !== undefined) {
      dispatch(setError({ errorFour: false }));
    }
  }, [temp, dispatch]);

  const handleOpenFields = () => {
    if (steps.step3) {
      setOpenSection({
        section1: false,
        section2: false,
        section3: false,
        section4: !openSection.section4,
        section5: false,
      });
    } else {
      NotificationManager.warning("", "Please complete the previous Step");
    }
  };
  const m = data?.m_financial_method?.value;
  const handleNext = () => {
    if (
      m === "cash" ||
      (m === "borrowed" &&
        n_financial_interest_rate_monthly !== undefined &&
        o_financial_interest_rate_annual !== undefined)
    ) {
      setSteps({ ...steps, step4: true });
      NotificationManager.success(
        "",
        "This step is completed successfully",
        1000
      );
      setOpenSection({
        section1: false,
        section2: false,
        section3: false,
        section4: false,
        section5: true,
      });
    } else {
      NotificationManager.warning("", "Please fill all the fields", 1000);
    }
  };
  return (
    <div className={`${className}`}>
      {/* Section header */}

      <InputSectionHeader
        headerName="Financing Method"
        errorFour={error?.errorFour}
        step4={steps.step4}
        onClick={() => handleOpenFields()}
      />

      <div
        className={`mx-8 pl-3 border-l-2 border-green-300 pt-2 ${
          !openSection.section4 && "hidden"
        }`}
      >
        {/* first drop down input */}

        <div className="grid grid-cols-4 gap-2">
          <p className="col-span-4 md:col-span-2 text-sm  leading-6">
            Do you pay for advertising with cash or borrowed funds (like a loan
            or a credit card)?
          </p>
          <InputDropdown
            className="col-span-4 md:col-span-2"
            options={first_dropdown_options}
            defaultValue={data?.m_financial_method}
            onChange={(e) => dispatch(addInputData({ m_financial_method: e }))}
          />
        </div>
        <div
          className={`${
            data?.m_financial_method?.value === "cash" ? "hidden" : "block"
          }`}
        >
          <div className="hidden  md:grid grid-cols-8 gap-2 mt-2">
            <div className="col-span-4 text-sm"></div>
            <p className="col-span-2 text-xs md:text-sm text-center">Monthly</p>
            <p className="col-span-2 text-xs md:text-sm text-center">Annual</p>
          </div>

          <div className={`grid grid-cols-8 gap-2 mt-2 `}>
            <p className="col-span-8 md:col-span-4 text-sm  leading-6">
              What is the effective interest rate on your borrowed funds?
            </p>

            <p className="col-span-4 text-xs md:text-sm text-left md:hidden ">
              Monthly
            </p>
            <p className="col-span-4 text-xs md:text-sm text-left md:hidden">
              Annual
            </p>

            <InterestRateInputMonthly
              setTemp={setTemp}
              handleFinancialInterest={handleFinancialInterest}
              handleFinantialInterestDataUpdate={
                handleFinantialInterestDataUpdate
              }
              setChangeFromAnnual={setChangeFromAnnual}
              n_financial_interest_rate_monthly={
                n_financial_interest_rate_monthly
              }
            />
            <InterestRateInputAnnual
              setTemp={setTemp}
              setChangeFromMonthly={setChangeFromMonthly}
              handleFinancialInterest={handleFinancialInterest}
              handleFinantialInterestDataUpdate={
                handleFinantialInterestDataUpdate
              }
              o_financial_interest_rate_annual={
                o_financial_interest_rate_annual
              }
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-green-400 px-10 py-2 rounded-md text-white mt-5"
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default FinancingMethodSection;
