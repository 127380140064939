import React from "react";
import Select from "react-select";

const InputDropdown = ({ className, options, onChange, defaultValue }) => {
  const customStyles = {
    control: (base, state) => ({
      ...base,
      background: "#212930",
      height: "48px",
      border: "0",
      borderBottom: "2px solid #69D49A",
      boxShadow: "none",
      "&:hover": {
        border: "0",
        borderBottom: "2px solid #69D49A",
      },
      borderRadius: "6px",
      cursor: "pointer",
    }),
    singleValue: (base, state) => ({
      ...base,
      color: "white",
      textAlign: "left",
    }),
    menu: (base, state) => ({
      ...base,
      background: "#f4efef",
    }),

    option: (base, state) => ({
      ...base,

      //  background: "#11141E",
      color: state.isSelected ? "white" : "black",
      cursor: "pointer",
      backgroundColor: state.isSelected ? "#3183AB" : "#f4efef",
    }),
  };

  return (
    <div className={className}>
      <Select
        styles={customStyles}
        onChange={onChange}
        options={options}
        defaultValue={defaultValue}
        isSearchable={false}
      />
    </div>
  );
};

export default InputDropdown;
