import React from "react";
import logo from "../../Assets/logo.png";

const Footer = () => {
  return (
    <>
      <div className="grid grid-cols-3">
        <div className="h-1" style={{ background: "#002f75" }}></div>
        <div className="h-1" style={{ background: "#8b1800" }}></div>
        <div className="h-1" style={{ background: "#095b00" }}></div>
      </div>

      <div className="bg-black py-10 font-karla ">
        <div className="text-sm md:text-lg lg:text-xl text-white mx-6 md:mx-32 lg:mx-80 text-center">
          <div>
            <p>
              Revenue Roll's mission is to change the current marketing and
              lending paradigm to align marketing compensation and lender
              interest with the success of the revenue they create.
            </p>
            <p className="mt-5">Message us anytime at info@revenueroll.com</p>
          </div>
          <div className="w-full grid place-items-center mt-5">
            <img className={"h-10 md:h-14"} src={logo} alt="not found" />
          </div>
        </div>
        <div className="mx-5 lg:mx-20 text-white text-sm md:text-base flex justify-between mt-5">
          <p className="text-left mr-6 md:mr-0 ">
            © 2021 Revenue Roll Inc. | All Rights Reserved.
          </p>
          <p className="text-right">Privacy Policy | Terms & Conditions</p>
        </div>
      </div>
    </>
  );
};

export default Footer;
