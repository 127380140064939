import React from "react";

const SideTitles = () => {
  return (
    <div className="h-[500px] md:h-[600px] pt-[69px] md:pt-[105px]  bg-gray-100 text-[8px] md:text-[10px]  2xl:text-[11px]">
      <div className="h-[43.5%] sm:h-[37.8%] md:h-[36.2%]  lg:h-[41.8%] xl:h-[36.2%] 2xl:text-sm bg-toolTip text-gray-800 font-bold grid place-items-center text-center ">
        Pre- <br /> Campaign
      </div>
      <div className="h-[56.5%] sm:h-[51%] md:h-[50%] xl:h-[248px] lg:h-[52.5%] 2xl: mt-[48px] bg-green-400  2xl:text-sm text-gray-800 font-bold grid place-items-center text-center">
        Campaign Returns
      </div>
    </div>
  );
};

export default SideTitles;
