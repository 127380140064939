import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import RASInput from "../../../InputSections/RASInput";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const width = window.screen.width;
export const options = {
  layout: {
    padding: {
      top: 15,
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: function (context) {
          if (context?.raw > 0) {
            return `${context?.dataset?.label}: ${parseInt(
              context?.raw?.toFixed()
            )?.toLocaleString()}`;
          } else if (context.raw < 0) {
            return `${context?.dataset?.label}: (${parseInt(
              (context?.raw * -1)?.toFixed()
            )?.toLocaleString()})`;
          }
        },
      },
    },
    title: {
      display: true,
      //   text: "Return On Ad Spend",
      position: "bottom",
    },
    legend: {
      position: "bottom",

      labels: {
        boxWidth: 15,
        boxHeight: 15,
        usePointStyle: true,
        padding: 20,
        textAlign: "right",
        color: "#ffffff",
      },
    },
  },
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index",
    intersect: false,
  },
  animation: {
    delay: (context) => {
      let delay = 0;
      if (context.type === "data" && context.mode === "default") {
        delay = context.dataIndex * 300;
      }
      return delay;
    },
  },
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        color: "#ffffff",
        // font: {
        //   size: 9,
        // },
        padding: 10,
      },
      title: {
        display: true,
        text: "Return on Ad Spend",
        color: "#ffffff",
        align: "center",
        font: {
          weight: "normal",
        },
        padding: {
          top: 10,
        },
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
      ticks: {
        color: "#ffffff",
        // font: {
        //   size: 9,
        // },
        callback: function (value, index, ticks) {
          return value < 0
            ? `(${(value * -1).toLocaleString()})`
            : value?.toLocaleString();
        },
      },
    },
  },
};
const barThickness = (context) => {
  if (width > 820) {
    return 50;
  } else if (width > 680) {
    return 40;
  } else if (width < 680) {
    return 25;
  }
};
export const data = (as, cc, fc, mr, labels) => {
  return {
    labels,
    datasets: [
      {
        label: "Ad Spend",
        data: as,
        backgroundColor: "#5eb7e5",
        stack: "Stack 1",
        barThickness: barThickness(),
        datalabels: {
          color: "#ffffff",
          font: {
            size: 9,
          },
          align: "right",
          padding: {
            left: 50,
          },
          display: false,
          formatter: function (value, context) {
            // return context.chart.data.labels[context.dataIndex];
            if (value > 0) {
              return parseInt(value.toFixed()).toLocaleString();
            } else if (value < 0) {
              return `(${parseInt((value * -1)?.toFixed()).toLocaleString()})`;
            }
          },
        },
      },
      {
        label: "Contractor Cost",
        data: cc,
        backgroundColor: "#848484",
        stack: "Stack 1",
        barThickness: barThickness(),
        datalabels: {
          align: "bottom",
          anchor: "start",
          color: "#ffffff",
          font: {
            size: 9,
          },
          display: function (context) {
            return context.chart.width > 500;
          },
          formatter: function (value, context) {
            // return context.chart.data.labels[context.dataIndex];
            if (value > 0) {
              return parseInt(value.toFixed()).toLocaleString();
            } else if (value < 0) {
              return `(${parseInt((value * -1)?.toFixed()).toLocaleString()})`;
            }
          },
        },
      },
      {
        label: "Financial Costs",
        data: fc,
        backgroundColor: "#cfc641",
        stack: "Stack 1",
        barThickness: barThickness(),
        datalabels: {
          anchor: "end",
          align: "right",
          padding: {
            left: 50,
          },
          color: "#ffffff",
          font: {
            size: 9,
          },
          display: false,
          formatter: function (value, context) {
            if (value > 0) {
              return parseInt(value.toFixed()).toLocaleString();
            } else if (value < 0) {
              return `(${parseInt((value * -1)?.toFixed()).toLocaleString()})`;
            }
          },
        },
      },
      {
        label: "Monthly Revenue",
        data: mr,
        backgroundColor: "#3ccf91",
        stack: "Stack 1",
        barThickness: barThickness(),
        datalabels: {
          color: "#ffffff",
          font: {
            size: 9,
          },
          anchor: "end",
          offset: -2,
          align: "top",

          display: function (context) {
            return context.chart.width > 500;
          },
          formatter: function (value) {
            if (value > 0) {
              return parseInt(value.toFixed()).toLocaleString();
            } else if (value < 0) {
              return `(${parseInt((value * -1)?.toFixed()).toLocaleString()})`;
            }
          },
        },
      },
    ],
  };
};

const RASBarChart = ({ as, cc, fc, mr, labels }) => {
  return (
    <div className="mt-4 relative ">
      <div>
        <div className="text-sm md:flex  justify-center md:justify-evenly  text-white my-10 pb-1">
          <div className="">
            <div className="relative mt-6 ml-6 mb-8 md:mb-0 !mx-auto md:mt-4 z-100 md:ml-6 py-1 px-2 w-72 sm:w-80 lg:w-60 xl:w-80 bg-[#11141e]  ">
              <p>
                3. Our innovative credit product only accrues additional
                interest if our campaigns are working and we continue to deploy
                capital.
              </p>
              <div className="w-10  -left-6 -top-4   h-10 -z-10 border-4 rounded-full border-green-400 absolute"></div>
            </div>
          </div>
          <div className="">
            <div className="relative !mx-auto  mt-4 z-100 ml-6 py-1 px-2  w-72 sm:w-80 lg:w-60 xl:w-80 bg-[#11141e]   ">
              <p>
                4. Explore below how Revenue Roll can maximize your sales and
                returns with little up-front cash required by your business
              </p>
              <div className="w-10 h-10   -left-6 -top-4  -z-10 border-4 rounded-full border-green-400 absolute"></div>
            </div>
          </div>
        </div>

        <div className="text-xs md:text-sm text-gray-300 md:flex  items-center mt-2">
          <p>
            What % of your Ad Spend would you like to finance with RevRoll?{" "}
          </p>
          <div className="md:mx-2 flex items-center  mt-2 space-x-2 lg:mt-0 lg:space-x-2   ">
            <RASInput />
            <p className="md:mx-2 "> (Min 100%) </p>
          </div>
        </div>
      </div>
      <div>
        <p className="text-xs text-[#3ccf91] mt-2">
          **Greater than 100% implies expanding your advertising budget
        </p>
      </div>
      <div className="h-96 relative  mt-8 flex !items-center ">
        <div className="absolute w-[70%] md:w-[75%] -top-6 right-0 text-black bg-[#5eb7e5] px-2  text-center text-[9px] md:text-xs   py-[2px] ">
          Revenue Roll Managed
        </div>
        <div className="absolute md:px-2 text-black left-10 md:left-12 -top-6 text-[9px] md:text-xs whitespace-nowrap  py-[2px] bg-green-400 w-[16%] sm:w-[18%] lg:w-[16%] xl:w-[18%] text-center">
          Status Quo
        </div>
        <Bar options={options} data={data(as, cc, fc, mr, labels)} />
      </div>
    </div>
  );
};

export default RASBarChart;
