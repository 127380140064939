import React from "react";
import NumberFormat from "react-number-format";

const RevenueInputMonthly = ({
  temp,
  setTemp,
  setChangeFromAnnual,
  handleOnlineRevenue,
  handleOnlineRevenueDataUpdate,
  c_online_revenue_monthly,
}) => {
  return (
    <div
      className={`col-span-4 md:col-span-2 h-10 px-2 `}
      style={{ background: "#212930", borderBottom: "2px solid #69D49A" }}
    >
      <NumberFormat
        thousandsGroupStyle="thousand"
        prefix={"$ "}
        decimalSeparator="."
        displayType="input"
        type="text"
        thousandSeparator={true}
        allowNegative={true}
        className={`bg-inherit w-full h-full  caret-white focus:outline-none `}
        onValueChange={(e) => {
          setTemp(e.floatValue);
          setChangeFromAnnual(false);
          handleOnlineRevenue(e, "monthly");
        }}
        value={c_online_revenue_monthly}
        decimalScale={2}
        onBlur={() => {
          if (temp !== undefined) {
            // if (temp !== c_online_revenue_monthly) {
            handleOnlineRevenueDataUpdate("online_revenue");
            // }
          }
        }}
      />
    </div>
  );
};

export default RevenueInputMonthly;
