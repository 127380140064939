import React from "react";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import { useSearchParams } from "react-router-dom";
import Infogram from "../Infogram";
import Input from "../Input";
import SubFooter from "../SubFooter";

const IndexPage = () => {

  const [searchParams] = useSearchParams();
  const noLayout = searchParams.get('noLayout');

  return (
    <>
      <div className="grid grid-cols-1 px-4 lg:px-0 lg:grid-cols-5 my-5 font-primary text-white hello">
        <Input className="lg:col-span-2 md:mb-4" />
        <Infogram className="col-span-3" />
        <NotificationContainer />
      </div>
      {!noLayout && <SubFooter />}
    </>
  );
};

export default IndexPage;
