/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import GroupBarChart from "./ChartJs/GroupBarChart";
import HalfDoughnut from "./ChartJs/HalfDoughnut";
import StackBarChart from "./ChartJs/StackBarChart";
import { useSelector } from "react-redux";
import axios from "axios";
import TableData from "./TableData";
import RASBarChart from "./ChartJs/RASBarChart";
// import { BsChevronDown } from "react-icons/bs";
// import { MdDone } from "react-icons/md";

const Infogram = ({ className }) => {
  const [prevData, setPrevData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [openGraph, setOpenGraph] = useState(true);
  const [openTable, setOpenTable] = useState(false);
  const [showResult] = useState(true);

  const data = useSelector((state) => state?.data);

  const ApiCall = async () => {
    const apidata = await axios.post(
      "https://rr-calculator-gateway-9r1eosgv.uc.gateway.dev/v2/calculator",
      {
        ...data,
        a_client_type: data?.a_client_type?.value,
        b_event_type: data?.b_event_type?.value,
        m_financial_method: data?.m_financial_method?.value,
      }
    );
    return apidata;
  };
  useEffect(async () => {
    if (
      (data?.a_client_type &&
        data?.c_online_revenue_monthly !== undefined &&
        data?.d_online_revenue_annual !== undefined &&
        data?.e_online_event_monthly !== undefined &&
        data?.f_online_event_annual !== undefined &&
        data?.g_event_worth_monthly !== undefined &&
        data?.h_event_worth_annual !== undefined &&
        data?.i_revenue_earned_monthly !== undefined &&
        data?.j_revenue_earned_annual !== undefined) ||
      (data?.m_financial_method?.value === "borrowed" &&
        data?.n_financial_interest_rate_monthly !== undefined &&
        data?.o_financial_interest_rate_annual !== undefined)
    ) {
      if (JSON.stringify(data) === JSON.stringify(prevData)) {
        console.log("no changes");
      } else {
        const apidataPrev = await ApiCall();
        console.log("getting response", apidataPrev);
        if (apidataPrev.status === 200) {
          setPrevData(data);
          setGraphData(apidataPrev);
        }
      }
    } else {
      console.log("data not send");
    }
  }, [data]);

  const roi = graphData?.data?.investment_sensitivity?.current_roi;
  let NRaosImpv = [roi];
  let raosImpv = [0];
  let obj = graphData?.data?.investment_sensitivity?.investment;

  if (obj !== null && obj !== undefined) {
    Object?.keys(obj)
      ?.map((key) => obj[key])
      ?.forEach((sig) => {
        NRaosImpv?.push(+sig?.same);
        raosImpv?.push(+sig?.roas);
      });
  }

  const GroupBar = {
    NRaosImpv: NRaosImpv.slice(0, NRaosImpv.length - 1),
    raosImpv: raosImpv.slice(0, raosImpv.length - 1),
  };

  const contractorDT = graphData?.data?.minimize_contractor_cost?.contractor;
  const revenueDT = graphData?.data?.minimize_contractor_cost?.revenue;

  const stackedBar = {
    labels: ["Status Quo", "Same ROAS", "Improved ROAS"],
    revenue: [
      revenueDT?.status_quo,
      revenueDT?.same_roas,
      revenueDT?.improved_roas,
    ],
    ccost: [
      contractorDT?.status_quo,
      contractorDT?.same_roas,
      contractorDT?.improved_roas,
    ],
  };

  const RasApiData = graphData?.data?.roasSensitivity?.rr_managed;
  const RasApiCRData = graphData?.data?.roasSensitivity?.status_quo;
  // console.log("rrr", RasApiData);
  const RasGroupBar = {
    labels: [
      "Status Quo",
      RasApiData?.row1?.untitled_times?.toFixed(2) + "x",
      RasApiData?.row2?.untitled_times?.toFixed(2) + "x",
      RasApiData?.row3?.untitled_times?.toFixed(2) + "x",
      RasApiData?.row4?.untitled_times?.toFixed(2) + "x",
      RasApiData?.row5?.untitled_times?.toFixed(2) + "x",
    ],
    as: [
      RasApiCRData?.ad_spend,
      RasApiData?.row1?.ad_spend,
      RasApiData?.row2?.ad_spend,
      RasApiData?.row3?.ad_spend,
      RasApiData?.row4?.ad_spend,
      RasApiData?.row5?.ad_spend,
    ],

    cc: [
      RasApiCRData?.contractor_cost,
      RasApiData?.row1?.contractor_cost,
      RasApiData?.row2?.contractor_cost,
      RasApiData?.row3?.contractor_cost,
      RasApiData?.row4?.contractor_cost,
      RasApiData?.row5?.contractor_cost,
    ],
    fc: [
      RasApiCRData?.financial_costs,
      RasApiData?.row1?.financial_costs,
      RasApiData?.row2?.financial_costs,
      RasApiData?.row3?.financial_costs,
      RasApiData?.row4?.financial_costs,
      RasApiData?.row5?.financial_costs,
    ],
    mr: [
      RasApiCRData?.monthly_revenue,
      RasApiData?.row1?.monthly_revenue,
      RasApiData?.row2?.monthly_revenue,
      RasApiData?.row3?.monthly_revenue,
      RasApiData?.row4?.monthly_revenue,
      RasApiData?.row5?.monthly_revenue,
    ],
  };

  const tableData = graphData?.data?.return_calculations;

  const statusQuo =
    graphData?.data?.improved_ad_performance?.status_quo?.toFixed(1);
  const analytics =
    graphData?.data?.improved_ad_performance?.uplift_analytics_platform?.toFixed(
      1
    );
  const manage =
    graphData?.data?.improved_ad_performance?.uplift_managed?.toFixed(1);
  const DoughnutData = [statusQuo, analytics, manage];
  const DoughnutDataToShow = [
    statusQuo,
    analytics - statusQuo,
    manage - analytics,
  ];

  const blurb1 = graphData?.data?.minimize_contractor_cost?.blurb1;
  const blurb2 = graphData?.data?.minimize_contractor_cost?.blurb2;
  return (
    <div
      className={`lg:border-l-4 lg:border-green-600 md:px-5 mt-6 ${className}`}
    >
      <div
        className={`${showResult && openGraph ? "block " : "hidden lg:block"} ${
          openGraph ? "lg:block" : "lg:hidden"
        }`}
      >
        <div>
          <div>
            <div className="flex justify-between items-center">
              <h1 className=" md:text-3xl font-extrabold text-base">
                Revenue Roll Projected Performance Calculator
              </h1>
            </div>
            <hr className="h-1 rounded-full bg-white my-2" />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 md:gap-10">
            <HalfDoughnut temp={DoughnutData} showTemp={DoughnutDataToShow} />
            <StackBarChart
              labels={stackedBar.labels}
              revenue={stackedBar.revenue}
              ccost={stackedBar.ccost}
              blurb1={blurb1}
              blurb2={blurb2}
            />
          </div>
          <div className="w-full mx-auto">
            <GroupBarChart
              NRaosImpv={GroupBar.NRaosImpv}
              raosImpv={GroupBar.raosImpv}
            />
          </div>
          <div className="pt-4">
            <button
              onClick={() => {
                setOpenGraph(false);
                setOpenTable(true);
              }}
              className="py-2 mx-auto text-white font-bold flex justify-center my-4 bg-green-400 px-8    "
            >
              View More Details
            </button>
          </div>
        </div>
      </div>

      {/* {openTable && showResult && ( */}
      <div
        className={`${showResult && openTable ? "block " : "hidden"} ${
          openTable ? "lg:block" : "lg:hidden"
        }`}
      >
        <div>
          <div>
            <button
              onClick={() => {
                setOpenGraph(true);
                setOpenTable(false);
              }}
              className="relative  py-1 mx-auto text-white font-bold flex justify-center items-center my-4 bg-green-400  w-full  "
            >
              Close More Details
              <div className="bg-black text-gray-300 h-5 w-5  flex justify-center items-center rounded-full absolute right-2">
                x
              </div>
            </button>
          </div>
          <div>
            <div>
              <div className="text-sm md:flex  justify-center md:justify-evenly text-white my-10 pb-1">
                {/* <div className="relative">
                  <div className="w-10 left-2 md:left-0 -top-4 md:top-0  h-10 -z-10 border-4 rounded-full border-green-400 absolute"></div>
                  <div className="mt-6 mx-auto  md:mt-4 z-100  md:ml-6 py-1 px-2 w-72 bg-[#11141e]  ">
                    <p className="">
                      1. Revenue Roll is committed to prioritizing our clients'
                      success ahead of everything else.
                    </p>
                  </div>
                </div> */}
                <div className="">
                  <div className="relative mb-8 md:mb-0 mt-6 mx-auto  md:mt-4 z-100  md:ml-6 py-1 px-2 w-72 lg:w-64 xl:w-72 bg-[#11141e]  ">
                    <p className="">
                      1. Revenue Roll is committed to prioritizing our clients'
                      success ahead of everything else.
                    </p>
                    <div className="w-10 -left-6 -top-4   h-10 -z-10 border-4 rounded-full border-green-400 absolute"></div>
                  </div>
                </div>
                <div className="">
                  <div className="relative mt-4 z-100 mx-auto md:ml-6 py-1 px-2 w-72 lg:w-64 xl:w-72 bg-[#11141e]   ">
                    <p>
                      2. We've built a system that incentivizes us to grow
                      alongside you and help you scale your long-term vision.
                    </p>
                    <div className="w-10 h-10  -left-6 -top-4  -z-10 border-4 rounded-full border-green-400 absolute"></div>
                  </div>
                </div>
              </div>
            </div>

            <TableData tableData={tableData} />
          </div>
          <div className="">
            <RASBarChart
              as={RasGroupBar.as}
              cc={RasGroupBar.cc}
              fc={RasGroupBar.fc}
              mr={RasGroupBar.mr}
              labels={RasGroupBar.labels}
            />
          </div>
        </div>
      </div>
      {/* )} */}
    </div>
  );
};
export default Infogram;
