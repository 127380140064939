import React from "react";
import commaNumber from "comma-number";

const TableColumn = ({ columnData, columnType, title, subTitle }) => {
  const formatData = (data, fieldName) => {
    if (data > 0) {
      if (
        fieldName === "roas" ||
        fieldName === "return_on_campaign" ||
        fieldName === "Contractor_fees"
      ) {
        return commaNumber(Number(data).toFixed(1));
      } else {
        return commaNumber(Number(data).toFixed(0));
      }
    } else if (data < 0) {
      if (
        fieldName === "roas" ||
        fieldName === "return_on_campaign" ||
        fieldName === "Contractor_fees"
      ) {
        return `(${commaNumber((Number(data) * -1).toFixed(1))})`;
      } else {
        return `(${commaNumber((Number(data) * -1).toFixed(0))})`;
      }
    } else {
      if (fieldName === "customer_ad") {
        return 0;
      } else return "";
    }
  };

  return (
    <div
      className={`text-[8px] md:text-[10px]  2xl:text-[11px] text-center md:-mt-4 lg:mt-1 xl:-mt-4  ${
        columnType === "withBorder" ? "border-r  border-black" : ""
      } col-span-1`}
    >
      <p
        className={`h-9 ${
          title === "Improved ROAS"
            ? "pt-1 lg:pt-0 bg-green-200 text-gray-900 font-bold"
            : title === "Same ROAS"
            ? "pt-1 lg:pt-0 bg-red-300 text-gray-900 font-bold"
            : "pt-1 lg:pt-0"
        } border-b  border-black px-2`}
      >
        {title}{" "}
        <span
          className={`text-[9px] md:hidden ${
            title === "Improved ROAS" || title === "Same ROAS"
              ? "block"
              : "hidden"
          }`}
        >
          RevRoll Managed
        </span>
      </p>{" "}
      {/* 0 */}
      <p className=" h-9 border-b-[3px]  border-b-[#65B7E5] font-bold bg-gray-300 px-2 pt-3 md:pt-0">
        {subTitle}{" "}
      </p>{" "}
      {/* 0 */}
      <p className="h-4 md:h-5 text-right px-3">
        {formatData(columnData?.ad_spend)}
      </p>{" "}
      {/* 1 */}
      <p className="h-4 sm:h-4 md:h-5 text-right px-3">
        {formatData(columnData?.loan_from_traditional_sources)}
      </p>{" "}
      {/* 2 */}
      <p className="h-4 sm:h-4 md:h-5 text-right px-3">
        {formatData(columnData?.loan_from_revenue_roll)}
      </p>{" "}
      {/* 3 */}
      <p className="h-5 md:h-5 border-t border-black py-1 mb-6 font-bold text-right px-3">
        {formatData(columnData?.customer_ad_spend_outlay, "customer_ad")}
      </p>
      {/* 4 */}
      <p className="h-7 sm:h-4  md:h-5 lg:h-8 xl:h-5 text-right px-3">
        {formatData(columnData?.current_agency)}
      </p>{" "}
      {/* 5 */}
      <p className="h-7 sm:h-4 md:h-5 lg:h-8 xl:h-5 text-right px-3">
        {formatData(columnData?.revenue_roll_saas_analytics_cost)}
      </p>{" "}
      {/* 6*/}
      <p
        className="h-9 md:h-8 lg:h-9 xl:h-8 py-1 mb-12 font-bold text-right px-3"
        style={{
          borderTop: "1px solid black",
          borderBottom: "3px solid #65B7E5",
        }}
      >
        {formatData(columnData?.total_pre_campaign_cash_investment)}
      </p>{" "}
      {/* 7 */}
      <p className="h-4 md:h-5 border-t-[3px] border-green-400 text-right px-3">
        {formatData(columnData?.ad_spend)}
      </p>{" "}
      {/* 8 */}
      <p className="h-4 md:h-5 text-right px-3">{`${formatData(
        columnData?.roas,
        "roas"
      )}x`}</p>{" "}
      {/* 9 */}
      <p className="border-t border-black h-5 md:h-5 py-1 mb-6 font-bold text-right px-3">
        {formatData(columnData?.online_revenue_generated)}
      </p>{" "}
      {/* 10 */}
      <p className="h-7 sm:h-4 md:h-5 lg:h-8 xl:h-5 text-right px-3">
        {formatData(columnData?.pre_campaign_cash_investment)}
      </p>{" "}
      {/* 11 */}
      <p className="h-4 md:h-5 text-right px-3">
        {formatData(columnData?.loan_payback)}
      </p>{" "}
      {/* 12 */}
      <p className="h-4 md:h-5 text-right px-3">
        {formatData(columnData?.financial_costs)}
      </p>{" "}
      {/* 13 */}
      <p className="h-7 sm:h-4  md:h-5 text-right px-3">
        {formatData(columnData?.revenue_roll_performance_fee)}
      </p>{" "}
      {/* 14 */}
      <p className="border-t border-black  h-5 md:h-5 py-1 mb-6  font-bold text-right px-3">
        {formatData(columnData?.net_campaign_return)}
      </p>{" "}
      {/* 15 */}
      <p className="h-4 sm:h-4 md:h-5 text-right px-3">
        {`${formatData(
          columnData?.return_on_campaign_investment,
          "return_on_campaign"
        )}%`}
      </p>{" "}
      {/* 16 */}
      <p className="h-5  md:h-5  mb-5 md:mb-0 border-b-[3px] border-green-400 text-right px-3">{`${formatData(
        columnData?.contractor_fees_of_revenue,
        "Contractor_fees"
      )}%`}</p>{" "}
      {/* 17 */}
    </div>
  );
};

export default TableColumn;
