import React from "react";

const tableRowTitleData = [
  "",
  "",
  "",
  "Advertising Spend",
  "Less: Loan From Current Sources",
  "Less: Loan From Revenue Roll",
  "Customer Ad Spend Outlay",
  "Plus: Current Agency / Contractor Retainer",
  "Plus: Revenue Roll SaaS Analytics Cost",
  "Total Pre-Campaign Cash Investment",
  "Advertising Spend",
  "ROAS",
  "Online Revenue Generated",
  "Less: Pre-Campaign Cash Investment",
  "Less: Loan Payback",
  "Less: Financing Costs",
  "Less: Revenue Roll Performance Fee",
  "Net Campaign Return",
  "Return on Campaign Investment",
  "Contractor Fees as % of Revenue",
];

const TableRowTitle = ({ className }) => {
  return (
    <div className={`${className}  z-50 bg-gray-100 `}>
      {tableRowTitleData.map((rowTitle, index) => {
        return rowTitle !== "" ? (
          <p
            className={`  ${
              index === 6 || index === 12 //NOSONAR
                ? "border-t border-black font-bold py-1 mb-6"
                : index === 9 //NOSONAR
                ? "border-b-[3px] border-b-[#65B7E5] border-t border-t-black font-bold py-1 mb-12"
                : index === 10 //NOSONAR
                ? "border-t-[3px] border-green-400"
                : index === 19
                ? " border-b-[3px] border-green-400"
                : null
            } text-[8px] md:text-[10px]  2xl:text-[11px]  ${
              // index === 5 ||
              // index === 8 ||
              // index === 13 ||
              // index === 16 ||
              // index === 18
              //   ? "h-8 md:h-5"
              //   : index === 4
              //   ? "h-5"
              //   : index === 6
              //   ? "h-5 md:max-h-5"
              //   : index === 7
              //   ? " max-h-8"
              //   : index === 9
              //   ? "h-9 md:max-h-8"
              //   : "h-8 md:h-5"
              index === 3 ||
              index === 10 ||
              index === 11 ||
              index === 14 ||
              index === 15
                ? "h-4 md:h-5"
                : index === 19
                ? "h-5  md:h-5"
                : index === 18
                ? "h-4 sm:h-4 md:h-5"
                : index === 16
                ? "h-7 sm:h-4 md:h-5"
                : index === 4 || index === 5
                ? "h-4 sm:h-4 md:h-5"
                : index === 6 || index === 12 || index === 17
                ? "h-5"
                : index === 8
                ? "h-7 sm:h-4 md:h-5 lg:h-8 xl:h-5"
                : index === 9
                ? "h-9 md:h-8 lg:h-9 xl:h-8"
                : index === 7
                ? "h-7 sm:h-4 md:h-5 lg:h-8 xl:h-5"
                : index === 13
                ? "h-7 sm:h-4 md:h-5 lg:h-8 xl:h-5"
                : null
            } ${
              index === 17 ? "border-t border-black font-bold py-1 mb-6" : ""
            } pl-2`}
            key={index}
          >
            {rowTitle}
          </p>
        ) : (
          <p
            className={`text-xs  ${
              index === 2 && " border-b-[3px] border-b-[#65B7E5] "
            } h-9 ${index === 0 && "hidden md:block"} pl-2`}
            key={index}
          >
            {" "}
          </p>
        );
      })}
    </div>
  );
};

export default TableRowTitle;
