import React from "react";
import Header from "./Header";
import Footer from "./Footer"
import { useSearchParams } from "react-router-dom";

const Layout = ({ children }) => {
  const [searchParams] = useSearchParams();
  const noLayout = searchParams.get('noLayout');

  return (
    <div>
      {!noLayout && <Header />}
      {children}
      {!noLayout && <Footer />}
    </div>
  );
};

export default Layout;
