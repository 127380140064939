import React, { useEffect } from "react";

import InputSectionHeader from "../../InputSectionHeader";
import InputDropdown from "../../InputDropdown";
import { useDispatch, useSelector } from "react-redux";
import { addInputData } from "../../../../redux/slice/inputSlice";
import { NotificationManager } from "react-notifications";

const first_dropdown_options = [
  { value: "ecommerce", label: "E-Commerce/SaaS Revenue" },
  { value: "event", label: "Event-Driven" },
];

const second_dropdown_options = [
  { value: "leads", label: "Leads" },
  { value: "app_installs", label: "App Installs" },
  { value: "in_app_purchases", label: "In-App Purchases" },
  { value: "crowdfunders", label: "Crowdfunders" },
  { value: "other", label: "Other" },
];

const ClientTypeSection = ({
  className,
  openSection,
  setOpenSection,
  steps,
  setSteps,
  param,
}) => {
  const data = useSelector((state) => state?.data);
  const a = data?.a_client_type?.value;
  const b = data.b_event_type?.value;

  const dispatch = useDispatch();

  useEffect(() => {
    if (param === "event") {
      dispatch(
        addInputData({
          a_client_type: { value: "event", label: "Event-Driven" },
        })
      );
    } else if (param === "ecommerce") {
      dispatch(
        addInputData({
          a_client_type: {
            value: "ecommerce",
            label: "E-Commerce/SaaS Revenue",
          },
        })
      );
    }
  }, [param, dispatch]);
  useEffect(() => {
    setSteps(steps => { return {...steps, step1: false} });
  }, [a, b, setSteps]);

  const handleNext = () => {
    if (a === "ecommerce") {
      setSteps({ ...steps, step1: true });
      NotificationManager.success(
        "",
        "This step is completed successfully",
        1000
      );
      setOpenSection({
        section1: false,
        section2: true,
        section3: false,
        section4: false,
        section5: false,
      });
    } else if (a === "event" && b !== "") {
      setSteps({ ...steps, step1: true });
      NotificationManager.success(
        "",
        "This step is completed successfully",
        1000
      );
      setOpenSection({
        section1: false,
        section2: true,
        section3: false,
        section4: false,
        section5: false,
      });
    } else {
      NotificationManager.warning("", "Please fill all the fields", 1000);
    }
  };
  return (
    <div className={`${className}`}>
      {/* Section header */}

      <InputSectionHeader
        headerName="Client Type"
        step1={steps?.step1}
        onClick={() =>
          setOpenSection({
            section1: !openSection.section1,
            section2: false,
            section3: false,
            section4: false,
            section5: false,
          })
        }
      />

      <div
        className={`mx-8 pl-3 border-l-2 border-green-300 py-2 ${
          !openSection.section1 && "hidden"
        }`}
      >
        {/* first drop down input */}

        <div className="grid grid-cols-4 gap-2">
          <p className="col-span-4 md:col-span-2 text-sm leading-6">
            Does your business sell products (e-commerce) / services (SaaS)
            directly online? Or do you look to obtain leads (accountant, lawyer,
            etc.)?
          </p>
          <InputDropdown
            className="col-span-4 md:col-span-2"
            options={first_dropdown_options}
            onChange={(e) => dispatch(addInputData({ a_client_type: e }))}
            defaultValue={
              param === "event"
                ? { value: "event", label: "Event-Driven" }
                : { value: "ecommerce", label: "E-Commerce/SaaS Revenue" }
            }
          />
        </div>

        {/* Second drop down input */}
        {data?.a_client_type?.value === "event" && (
          <div className="grid grid-cols-4 gap-2 mt-2">
            <p className=" col-span-4 md:col-span-2 text-sm leading-6">
              What type of event are you looking for?
            </p>
            <InputDropdown
              className="col-span-4 md:col-span-2"
              options={second_dropdown_options}
              onChange={(e) => dispatch(addInputData({ b_event_type: e }))}
              name="b_event_type"
              defaultValue={data?.b_event_type}
            />
          </div>
        )}
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-green-400 px-10 py-2 rounded-md text-white mt-5"
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default ClientTypeSection;
