import React from "react";

const FilterIcon = ({ showFilterMenu, setShowFilterMenu }) => {
  return (
    <div
      className={`flex justify-between px-3 transition duration-300 ${
        showFilterMenu ? "translate-x-0" : "-translate-x-[75%] "
      }`} 
    >
      <p className="text-base text-gray-600 bg-gray-100 font-bold">Filter Columns</p>
      <div
        className="lg:hidden cursor-pointer flex flex-col relative"
        onClick={() => setShowFilterMenu(!showFilterMenu)}
      >
        <div
          className={`h-1 bg-gray-600 rounded-sm transform mb-2 ${
            showFilterMenu
              ? "rotate-45 w-6 translate-y-3"
              : "rotate-0 w-7 translate-y-0"
          } transition duration-500`}
        ></div>
        <div
          className={`h-1 bg-gray-600 rounded-sm w-5 mb-2 transform ${
            showFilterMenu ? "opacity-0" : "opacity-1"
          } transition duration-300`}
        ></div>
        <div
          className={`h-1 bg-gray-600 rounded-sm transform ${
            showFilterMenu
              ? "-rotate-45 w-6 -translate-y-3"
              : "rotate-0 w-3 translate-y-0"
          } transition duration-500`}
        ></div>
      </div>
    </div>
  );
};

export default FilterIcon;
