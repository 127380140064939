import React from "react";
import NumberFormat from "react-number-format";

const RetainAgencyInputAnnual = ({
  setTemp,
  setChangeFromMonthly,
  handleMarketingAgency,
  q_marketing_agency_cost_annual,
  handleMarketingAgencyDataUpdate,
}) => {
  return (
    <div
      className={`col-span-4 md:col-span-2 h-10 px-2  `}
      style={{
        background: "#212930",
        borderBottom: "2px solid #69D49A",
      }}
    >
      <NumberFormat
        thousandsGroupStyle="thousand"
        prefix={"$ "}
        // suffix={" %"}
        decimalSeparator="."
        displayType="input"
        type="text"
        thousandSeparator={true}
        allowNegative={true}
        className={`bg-inherit w-full h-full  caret-white focus:outline-none `}
        onValueChange={(e) => {
          setTemp = e.floatValue;
          setChangeFromMonthly(false);
          handleMarketingAgency(e, "annual");
        }}
        value={q_marketing_agency_cost_annual}
        decimalScale={2}
        onBlur={() => {
          // if (temp !== undefined) {
          handleMarketingAgencyDataUpdate();
          // }
        }}
      />
    </div>
  );
};

export default RetainAgencyInputAnnual;
