import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import ClientTypeSection from "./Sections/ClientTypeSection";
import FinancingMethodSection from "./Sections/FinancingMethodSection";
import MarketingAgencySection from "./Sections/MarketingAgencySection";
import OnlineAdvertisingSection from "./Sections/OnlineAdvertisingSection";
import OnlineRevenueSection from "./Sections/OnlineRevenueSection";

const Input = ({ className }) => {
  const [steps, setSteps] = useState({
    step1: false,
    step2: false,
    step3: false,
    step4: false,
    step5: false,
    step6: false,
  });
  const [openSection, setOpenSection] = useState({
    section1: true,
    section2: false,
    section3: false,
    section4: false,
    section5: false,
    section6: false,
  });
  const [searchParams] = useSearchParams();
  const param = searchParams.get("type");
  const c = searchParams.get("c_online_revenue_monthly");
  const e = Number(searchParams.get("e_online_event_monthly"))
  const g = Number(searchParams.get("g_event_worth_monthly"))
  const i = Number(searchParams.get("i_revenue_earned_monthly"))
  const ga = Number(searchParams.get("ga_measure_success_pointer"))
  //  type = ecommerce&&c_online_revenue_monthly=somedata
  //  type = event&&e_online_event_monthly=somedata&&g_event_worth_monthly=somedata&&i_revenue_earned_monthly=somedata
  return (
    <div className={`${className}`}>
      <ClientTypeSection
        openSection={openSection}
        setOpenSection={setOpenSection}
        steps={steps}
        setSteps={setSteps}
        param={param}
      />
      <OnlineRevenueSection
        openSection={openSection}
        setOpenSection={setOpenSection}
        steps={steps}
        setSteps={setSteps}
        param={param}
        c={c}
        e={e}
        g={g}
        i={i}
      />
      <OnlineAdvertisingSection
        openSection={openSection}
        setOpenSection={setOpenSection}
        steps={steps}
        setSteps={setSteps}
        ga={ga}
      />
      <FinancingMethodSection
        openSection={openSection}
        setOpenSection={setOpenSection}
        steps={steps}
        setSteps={setSteps}
      />
      <MarketingAgencySection
        openSection={openSection}
        setOpenSection={setOpenSection}
        steps={steps}
        setSteps={setSteps}
      />
    </div>
  );
};

export default Input;
