import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = (temp, showTemp) => {
  return {
    labels: [
      "Status Quo",
      "Uplift From Analytics",
      "Uplift From Managed Services",
    ],
    reDraw: false,
    datasets: [
      {
        label: [
          "Status Quo",
          "Uplift From Analytics",
          "Uplift From Managed Services",
        ],
        data: showTemp,
        backgroundColor: ["#3ccf91", "#f0ca69", "#5eb7e5"],
        borderColor: ["#3ccf91", "#f0ca69", "#5eb7e5"],
        borderWidth: 1,
        needleValue: 4,
        datalabels: {
          color: "#ffffff",
          formatter: function (value, context) {
            if (context.dataIndex === 0) {
              return "Status Quo";
            } else if (context.dataIndex === 1) {
              return "Analytics";
            } else if (context.dataIndex === 2) {
              return "Managed Services";
            }
          },
          font: {
            size: 9,
          },
        },
      },
    ],
  };
};

const HalfDoughnut = ({ temp, showTemp }) => {
  // tooltipData(temp);
  const options = {
    rotation: -90,
    circumference: 180,
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 20,
        bottom: 20,
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label(tooltipItems) {
            if (tooltipItems.dataIndex === 0) {
              return `${tooltipItems.label}: ${temp[tooltipItems.dataIndex]} x`;
            } else if (tooltipItems.dataIndex === 1) {
              return `${tooltipItems.label}: ${temp[tooltipItems.dataIndex]} x`;
            } else if (tooltipItems.dataIndex === 2) {
              return `${tooltipItems.label}: ${temp[tooltipItems.dataIndex]} x`;
            }
            // return `${tooltipItems.label}: ${tooltipItems.formattedValue} x`;
          },
        },
      },
      legend: {
        position: "bottom",
        align: "center",
        fullSize: true,
        onClick: null,
        labels: {
          boxWidth: 15,
          boxHeight: 15,
          usePointStyle: true,
          padding: 20,
          textAlign: "center",
          color: "#ffffff",
        },
      },
    },
    animation: {
      delay: (context) => {
        let delay = 0;
        if (context.type === "data" && context.mode === "default") {
          delay = context.dataIndex * 1000 + context.datasetIndex * 500;
        }
        return delay;
      },
    },
  };

  return (
    <div className="h-80  relative">
      <div className="absolute  w-full top-8 grid grid-cols-2 space-x-2 ">
        <div className="col-span-1 text-xs text-center md:py-[8px] lg:py-[8px] xl:py-[2px] text-black bg-green-400 md:mr-2">
          Status Quo
        </div>
        <div className="col-span-1 text-xs text-center py-[2px]  px-1 text-black bg-[#5eb7e5]">
          Revenue Roll Managed
        </div>
      </div>
      <div className="flex items-center ">
        <p className="text-xs md:text-sm font-semibold text-gray-300  py-2">
          Revenue Roll Improves Ad Performance
        </p>
        <div className="group relative">
          <div className="">
            <div className=" ml-2 w-5 h-5 lg:mt-[-18px] xl:mt-0 text-sm rounded-full bg-gray-500 flex items-center justify-center cursor-pointer">
              <p>?</p>
            </div>
            <div className="hidden group-hover:block">
              <div
                className="w-5 h-5 rounded-sm bg-toolTip rotate-45 absolute z-50 border-b border-r border-white"
                style={{ right: "7%", bottom: "27px" }}
              ></div>
              <div className="absolute bottom-9 w-72 -left-[180px] md:w-96 md:-left-10  px-5 py-2 bg-toolTip text-xs rounded-md border border-white text-black">
                <p>
                  • Revenue Roll can grow your revenue from advertising by as
                  much as 100%
                </p>
                <p>
                  • We maximize your revenue earned from advertising dollars
                  using our innovative analytics platform.
                </p>
                <p>
                  • We have far superior analytics tools that are typically only
                  available to enterprise clients at very high prices.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="h-72 mt-9">
        <Doughnut data={data(temp, showTemp)} options={options} />
      </div>
    </div>
  );
};

// const Niddle = ({data}) =>{

//   const [niddle,setNiddle] = useState();

//   const handleNiddleRotation = () =>{

//   }

//   return (<div className="w-full absolute top-[65%]">
//           <div className={`w-1/2 h-1 bg-white transform transition duration-300 `} style={{transformOrigin:"100% 100%",transform:`rotate(${niddle}deg)`,borderRadius:"5px"}}>

//           </div>
//           <button onClick={()=>setNiddle(data[0]*(180/data[2]))}>Rotate</button>
//         </div>)
// }

export default HalfDoughnut;
