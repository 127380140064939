import React from "react";

const SubFooter = () => {

  const openCalendar = () => {
    window.open("https://cal.mixmax.com/alekburk-659/1");
  }

  return (
    <div className="md:h-80 gap-5 md:gap-0 mx-5 lg:mx-10 flex justify-center items-center font-primary text-white border-t-2 border-green-500 pt-5 md:pt-10 mb-5 md:mb-10  ">
      <div
        className="bg-cardBg space-y-4 rounded-md md:mx-5 lg:mx-10 py-10 px-8 "
        style={{ boxShadow: " 0 3px 32px 0 rgba(56, 64, 94, 0.51)" }}
      >
        <div className="flex items-center justify-between">
          <p className="text-[14px] md:text-[20px] font-bold tracking-wider mr-2 ">
            Want To Learn More ?
          </p>

          <button onClick={openCalendar} className="bg-[#3ccf91] md:hover:bg-green-500 transition duration-200 rounded-full text-white w-32 py-1 md:py-2 md:w-52 text-[11px] md:text-sm lg:text-base">
            Get Proposal & Speak
          </button>
        </div>
        <div className="flex items-center justify-between">
          <div className="mr-2">
            <p className=" text-[14px] md:text-[20px]  font-bold tracking-wider ">
              Like What You See ?
            </p>
            <p className="text-[11px] md:text-[12px]">
              Try A Month Risk Free Trial
            </p>
          </div>

          <button onClick={openCalendar} className="bg-[#3ccf91] md:hover:bg-green-500 transition duration-200 rounded-full text-white w-32 py-1 md:py-2 md:w-52 text-[11px] md:text-sm lg:text-base">
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubFooter;
