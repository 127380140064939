import React from "react";
import NumberFormat from "react-number-format";

const OnlineChannelInputAnnual = ({
  setTemp,
  setChangeFromMonthly,
  handleHowManyEvents,
  handleOnlineRevenueDataUpdate,
  f_online_event_annual,
}) => {
  return (
    <div
      className={`col-span-4 md:col-span-2 h-10 px-2 `}
      style={{
        background: "#212930",
        borderBottom: "2px solid #69D49A",
      }}
    >
      <NumberFormat
        thousandsGroupStyle="thousand"
        // prefix={"$ "}
        // suffix={" %"}
        decimalSeparator="."
        displayType="input"
        type="text"
        thousandSeparator={true}
        allowNegative={true}
        className={`bg-inherit w-full h-full  caret-white focus:outline-none `}
        onValueChange={(e) => {
          setTemp(e.floatValue);
          setChangeFromMonthly(false);
          handleHowManyEvents(e, "annual");
        }}
        value={f_online_event_annual}
        decimalScale={2}
        onBlur={() => {
          // if (temp !== undefined) {
          handleOnlineRevenueDataUpdate("online_event");
          // }
        }}
      />
    </div>
  );
};

export default OnlineChannelInputAnnual;
